import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import api from '../api/CallAdminApi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { refreshAll } from '../../store/filterReducer';
import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';

const Login: React.FunctionComponent = () => {
  const [id, setId] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const login = () => {
    const login = async () => {
      try {
        await api.Login(id, pass);
        // 一覧の検索条件初期化
        dispatch(refreshAll());
        navigate('/admin/reports');
      } catch (e: any) {
        // ログインエラー表示
        if (e.response.status === 401 || e.response.status === 400) {
          toast.error('ログインできませんでした。', toastConfig());
          return;
        }
        showBoundary(e.response);
      }
    };
    login();
  };

  const keyDownEvents = (event: any) => {
    if (event && event.keyCode === 13) {
      login();
    }
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card className="card-center">
              <Card.Img src={`${process.env.PUBLIC_URL}/images/sartras_logo.png`} className="logo mt-20" />
              <Col className="text-center">
                <h5 className="login-subtitle">利用報告入力フォームTSUMUGI</h5>
                <h6>管理者用</h6>
              </Col>
              <Card.Body>
                <Form>
                  <Form.Group>
                    <label htmlFor="login_id">ID</label>
                    <Form.Control
                      type="text"
                      id="login_id"
                      onChange={(event) => {
                        setId(event.target.value);
                      }}
                      onKeyDown={(event: any) => {
                        keyDownEvents(event);
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <label htmlFor="login_pass">パスワード</label>
                    <Form.Control
                      type="password"
                      id="login_pass"
                      onChange={(event) => {
                        setPass(event.target.value);
                      }}
                      onKeyDown={(event: any) => {
                        keyDownEvents(event);
                      }}
                    />
                  </Form.Group>
                </Form>
              </Card.Body>
              <Card.Footer className="ta-right">
                <Button
                  variant="primary"
                  type="button"
                  onClick={() => {
                    login();
                  }}
                >
                  ログイン
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
