import { unexpectedExceptionClient, expectedExceptionClient } from './AxiosClientProvider';
import { Report } from '../interface/Report';
import { OwnerUserInfo } from '../interface/OwnerUserInfo';
import { Institution } from '../interface/Institution';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { BaseReport } from '../interface/BaseReport';
import { ClassInfo } from '../interface/ClassInfo';
import { Media } from '../interface/Media';
import { Work } from '../interface/Work';
import { Comment } from '../interface/Comment';
import { OwnerInstitutionsPageData } from '../interface/OwnerInstitutionsPageData';

const LOGIN_API = '/v1/owner/login';
const GET_PROFILE_API = '/v1/owner/profile';
const GET_PROFILE_MATCH_PASSWORD_API = '/v1/owner/profile/match-password';
const LOGOUT_API = '/v1/owner/logout';
const GET_REPORTS_API = '/v1/owner/reports';
const GET_INSTITUTIONS_API = '/v1/owner/institutions';
const BULK_DELETE_API = '/v1/owner/reports/bulk-delete';
const BULK_UPDATE_API = '/v1/owner/reports/bulk-update';
const GET_REPORT_API = '/v1/owner/reports/';
const GET_DEFAULT_USER_API = '/v1/owner/institutions/default-user';
const BULK_CREATE_API = '/v1/owner/reports/bulk-create';
const MARK_AS_NO_SUBMISSION_API = '/v1/owner/institutions/markAsNoSubmission/';
const GET_MANAGER_API = '/v1/owner/managers/';
const GET_ADDITIONAL_REPORT_NUMBER_INSTITUTIONS_API = '/v1/owner/institutions/additionalReportNumberInstitutions';

const Login = async (_id: string, password: string): Promise<any> => {
  return await expectedExceptionClient.post(LOGIN_API, { _id, password }).catch((error) => {
    throw error;
  });
};

const Logout = async (): Promise<any> => {
  return await unexpectedExceptionClient.post(LOGOUT_API).catch((error) => {
    throw error;
  });
};

const getProfile = async (): Promise<OwnerUserInfo> => {
  return await unexpectedExceptionClient
    .get(GET_PROFILE_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getReports = async (phase: string, institution: string): Promise<Report[]> => {
  return await unexpectedExceptionClient
    .get(GET_REPORTS_API, { params: { phase, institution } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getInstitutions = async (): Promise<Institution[]> => {
  return await unexpectedExceptionClient
    .get(GET_INSTITUTIONS_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const bulkDelete = async (ids: string[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_DELETE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkUpdate = async (ids: BulkUpdatePhase[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_UPDATE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkCreate = async (data: BaseReport[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_CREATE_API, data).catch((error) => {
    throw error;
  });
};

const getReport = async (id: string): Promise<Report> => {
  return await unexpectedExceptionClient
    .get(GET_REPORT_API + id)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const postReport = async (classInfo: ClassInfo, media: Media, work: Work, phase: string, user: string): Promise<any> => {
  return await expectedExceptionClient.post(GET_REPORTS_API, { phase, classInfo, media, work, user }).catch((error) => {
    throw error;
  });
};

const getDefaultUser = async (institution: string): Promise<string> => {
  return await unexpectedExceptionClient
    .get(GET_DEFAULT_USER_API, { params: { institution } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const deleteReport = async (id: string): Promise<any> => {
  return await expectedExceptionClient.delete(GET_REPORT_API + id).catch((error) => {
    throw error;
  });
};

const updatePhase = async (_id: string, phase: string, comment: Comment | null): Promise<Report> => {
  return await expectedExceptionClient
    .put(GET_REPORT_API + _id, { _id, phase, comment })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updatePassword = async (password: string): Promise<any> => {
  return await expectedExceptionClient.put(GET_PROFILE_API, { password }).catch((error) => {
    throw error;
  });
};

const putProfile = async (representativeName: string, phone: string, email: string): Promise<OwnerUserInfo> => {
  const results = await expectedExceptionClient.put(GET_PROFILE_API, {
    representativeName,
    phone,
    email,
  });
  return results.data;
};

const isMatchOldPassword = async (password: string): Promise<boolean> => {
  return await expectedExceptionClient
    .post(GET_PROFILE_MATCH_PASSWORD_API, { password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateReport = async (id: string, classInfo: ClassInfo, media: Media, work: Work): Promise<any> => {
  return await expectedExceptionClient.put(GET_REPORT_API + id, { classInfo, media, work }).catch((error) => {
    throw error;
  });
};

const markAsNoSubmission = async (id: string): Promise<any> => {
  const results = await expectedExceptionClient.put(MARK_AS_NO_SUBMISSION_API + id);
  return results.data;
};

const getManager = async (id: string): Promise<any> => {
  const results = await unexpectedExceptionClient.get(GET_MANAGER_API + id);
  return results.data;
};

const putManager = async (id: string, name: string, phone: string, email: string): Promise<any> => {
  const param = { name, phone, email };
  const results = await expectedExceptionClient.put(GET_MANAGER_API + id, param);
  return results.data;
};

const getAdditionalReportNumberInstitutions = async (): Promise<OwnerInstitutionsPageData[]> => {
  const results = await unexpectedExceptionClient.get(GET_ADDITIONAL_REPORT_NUMBER_INSTITUTIONS_API);
  return results.data;
};

const putNotificationDays = async (notificationDays: boolean[]): Promise<any> => {
  const results = await expectedExceptionClient.put(GET_PROFILE_API, { notificationDays });
  return results.data;
};

const exportedObject = {
  Login,
  Logout,
  getProfile,
  getReports,
  getInstitutions,
  postReport,
  getReport,
  bulkUpdate,
  bulkDelete,
  getDefaultUser,
  deleteReport,
  updatePhase,
  updatePassword,
  putProfile,
  isMatchOldPassword,
  updateReport,
  bulkCreate,
  markAsNoSubmission,
  getManager,
  putManager,
  getAdditionalReportNumberInstitutions,
  putNotificationDays,
};

export default exportedObject;
