import { Button, Modal, Form, Alert, Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';
interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  filterIds: (ids: string[]) => void;
}
const Component: React.FC<ModalProps> = ({ show, setShow, filterIds }) => {
  const [ids, setIds] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const search = () => {
    const arrayIds = ids
      .split('\n')
      .filter((id) => id !== '')
      .map((id) => id.trim());
    if (arrayIds.length === 0) {
      setMessage('シリアルコードを入力してください。');
      return;
    }
    setMessage('');
    filterIds(arrayIds);
    setIds('');
    setShow(false);
  };

  const closeModal = () => {
    setIds('');
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        closeModal();
      }}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>シリアルコード検索</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          {message !== '' && (
            <Row>
              <Col>
                <Alert variant="danger">{message}</Alert>
              </Col>
            </Row>
          )}
          <Row>
            <Col>右の欄にシリアルコードを入力して「検索」を押すと、該当する利用報告を検索できます。</Col>
            <Col>
              <Button
                variant="primary"
                onClick={() => {
                  search();
                }}
                disabled={ids === ''}
              >
                検索
              </Button>
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <div>同時に複数のシリアルコードを検索する場合は、改行したうえで続けて入力してください。</div>
              <div className="m-3">
                入力例
                <br />
                qawsedrftgyhujikolpqawse
                <br />
                qawsedrftgyhujikolpqawse
                <br />
                qawsedrftgyhujikolpqawse
                <br />
              </div>
              <div className="mt-1">※シリアルコードとは、利用報告1件ごとに割り当てられた固有の文字列です。</div>
            </Col>
            <Col>
              <Form.Group controlId="textarea">
                <Form.Control
                  as="textarea"
                  rows={20}
                  onChange={(event) => {
                    setIds(event.target.value);
                  }}
                  value={ids}
                />
              </Form.Group>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default Component;
