export const ACCOUNT = {
  USERS: '入力者',
  MANAGER: '教育機関担当者',
  OWNER: '設置者担当者',
  ADMIN: 'SARTRAS',
} as const;

export const getBossAccount = (account) => {
  switch (account) {
    case ACCOUNT.USERS:
      return ACCOUNT.MANAGER;
    case ACCOUNT.MANAGER:
      return ACCOUNT.OWNER;
    case ACCOUNT.OWNER:
      return ACCOUNT.ADMIN;
    default:
      throw new Error('存在しないアカウント権限です');
  }
};

export const getSubordinateAccount = (account) => {
  switch (account) {
    case ACCOUNT.MANAGER:
      return ACCOUNT.USERS;
    case ACCOUNT.OWNER:
      return ACCOUNT.MANAGER;
    case ACCOUNT.ADMIN:
      return ACCOUNT.OWNER;
    default:
      throw new Error('存在しないアカウント権限です');
  }
};

export default {
  ACCOUNT,
  getBossAccount,
  getSubordinateAccount,
};
