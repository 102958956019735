import { Form, Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import api from '../api/CallAdminApi';

interface FilterComponentProps {
  filterText: string;
  onFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filterText, onFilter }) => {
  const [options, setOptions] = useState<any[]>([]);
  useEffect(() => {
    const getData = async () => {
      const owners = await api.getOwners();
      setOptions(owners.map((owner: any) => ({ value: owner._id, name: owner.name })));
    };
    getData();
  }, []);

  return (
    <Form>
      <Row>
        <Form.Label column md="5" className="filter-label pr-0">
          教育機関設置者
        </Form.Label>
        <Col md="7" className="pl-0">
          <Form.Control as="select" value={filterText} onChange={onFilter}>
            <option value="">すべて</option>
            {options.map((item, index) => (
              <option key={index} value={item.value}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterComponent;
