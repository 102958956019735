import { expectedExceptionClient, unexpectedExceptionClient, imageClient } from './AxiosClientProvider';
import FormData from 'form-data';
import { MediaCategory } from '../interface/MediaCategory';

const BASE_API_URL = process.env.REACT_APP_API_URL;
const GET_MEDIA_SCHEMA_API = BASE_API_URL + '/v1/mediaSchema';
const GET_WORK_SCHEMA_API = BASE_API_URL + '/v1/workSchema';
const GET_SHEMA_API = BASE_API_URL + '/v1/schema';
const GET_MEDIA_CATEGORY_API = BASE_API_URL + '/v1/mediaCategory';
const GET_SUB_CATEGORY_API = BASE_API_URL + '/v1/subCategory';
const GET_GENERATE_IMAGE_URI_API = BASE_API_URL + '/v1/generateImageUri';

const getMediaSchema = async (categories: string[]): Promise<any> => {
  let param: any = { category: categories[0] };
  for (let i = 1; i < categories.length; i++) {
    const key = 'category' + String(i + 1);
    param[key] = categories[i];
  }
  return await unexpectedExceptionClient
    .get(GET_MEDIA_SCHEMA_API, { params: param })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getWorkSchema = async (categories: string[], subCategory: string): Promise<any> => {
  let param: any = { category: categories[0] };
  for (let i = 1; i < categories.length; i++) {
    const key = 'category' + String(i + 1);
    param[key] = categories[i];
  }
  param['subCategory'] = subCategory;
  return await unexpectedExceptionClient
    .get(GET_WORK_SCHEMA_API, { params: param })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getSchemaById = async (regulationId: string): Promise<any> => {
  const response = await expectedExceptionClient.get(`${GET_SHEMA_API}/${regulationId}`);
  if (!response) return undefined;
  return response.data;
};

const getMediaCategory = async (category: string): Promise<MediaCategory[]> => {
  return await unexpectedExceptionClient
    .get(GET_MEDIA_CATEGORY_API, { params: { category } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getSubCategory = async (categories: string[]): Promise<string[]> => {
  const params: object = { category: categories[0] };
  for (let i = 1; i < categories.length; i++) {
    const key = 'category' + String(i + 1);
    params[key] = categories[i];
  }
  // スキーマの更新により分岐が見つからないケースが想定されるため、expectedExceptionClientです。
  return await expectedExceptionClient
    .get(GET_SUB_CATEGORY_API, { params })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      if (error.response.status === 404) return undefined;
      throw error.response;
    });
};

const getImageUri = async (file: File): Promise<any> => {
  const form = new FormData();
  form.append('image', file);
  return await imageClient
    .post(GET_GENERATE_IMAGE_URI_API, form)
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
};

const exportedObject = {
  getMediaSchema,
  getWorkSchema,
  getSchemaById,
  getMediaCategory,
  getSubCategory,
  getImageUri,
};

export default exportedObject;
