import { Row, Col, Button } from 'react-bootstrap';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { ClassInfo } from '../interface/ClassInfo';
import Stepper from '../components/Stepper';
import InputForm from './InputMediaForm';
import { getLessonSchema, getLessonUiSchema } from '../utils';
import React, { createRef, useState, useEffect } from 'react';
import BackButton from './BackButton';
import { setLessonTmp, removeBigMediaCategoriesTmpById } from '../localStorageUtils';

interface Props {
  userInfo: BaseUserInfo;
  movePage: () => void;
  formData: ClassInfo;
}

const InputLesson: React.FC<Props> = ({ userInfo, movePage, formData }) => {
  const inputFormRef = createRef<HTMLButtonElement>();
  const [saveData, setSaveData] = useState<ClassInfo>({} as ClassInfo);

  const changeInputFormData = (formData: ClassInfo, type: string) => {
    if (type === 'submit') {
      setLessonTmp(userInfo._id, formData);
      removeBigMediaCategoriesTmpById(userInfo._id);
      movePage();
    } else {
      setSaveData(formData);
    }
  };

  const nextPage = (): void => {
    inputFormRef.current?.click();
  };

  useEffect(() => {
    setSaveData(formData);
  }, [formData]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="ta-left">
            <BackButton />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Stepper activeNum={0} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4>STEP1 著作物の送信を行った授業の情報を入力してください</h4>
          <hr className="border-1-solid" />
          <InputForm
            ref={inputFormRef}
            schema={getLessonSchema()}
            setFormData={changeInputFormData}
            isValidationOff={false}
            formData={saveData}
            uiSchema={getLessonUiSchema()}
          />
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 12 }}>
          <div className="ta-right">
            <Button
              variant="success"
              as="input"
              type="submit"
              onClick={() => {
                nextPage();
              }}
              value="次へ"
              className="m-10"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InputLesson;
