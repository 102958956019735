import { Button, Modal, Table } from 'react-bootstrap';
import { useState, useEffect } from 'react';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  itemList: any[];
  nextStep: (item: any) => void;
}

const SelectModal: React.FC<ModalProps> = ({ show, setShow, itemList, nextStep }) => {
  const [selectItem, setSelectItem] = useState<any>({});
  const [selectIndex, setSelectIndex] = useState<number>(-1);

  const selectListItem = (item: any, index: number) => {
    setSelectIndex(index);
    setSelectItem(item);
  };

  useEffect(() => {
    if (!show) return;
    setSelectIndex(-1);
    setSelectItem({});
  }, [show]);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>検定教科書を選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          検索条件に一致した検定教科書が複数あります。以下から選択してください。
          <Table bordered>
            <thead>
              <tr>
                <th>検定年度</th>
                <th>学校種別</th>
                <th>学年</th>
                <th>教科</th>
                <th>検定教科書名</th>
              </tr>
            </thead>
            <tbody>
              {itemList.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    selectListItem(item, index);
                  }}
                  className={selectIndex === index ? 'alert-primary' : ''}
                >
                  <td>{item.certificateYear}</td>
                  <td>{item.schoolType}</td>
                  <td>{item.grade}</td>
                  <td>{item.textSymbol}</td>
                  <td>{item.title}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          disabled={!Object.keys(selectItem).length}
          onClick={() => {
            nextStep(selectItem);
          }}
        >
          選択
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
