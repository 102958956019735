import { WidgetProps } from '@rjsf/utils';
import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import SelectBroadcasterModal from './modal/SelectBroadcasterModal';

function InputBroadcasterNameWidget(props: WidgetProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [broadcasterName, setBroadcasterName] = useState<string>(props.value ? props.value : '');
  const changeBroadcasterName = (value: string) => {
    props.onChange(value ? value : undefined);
    setBroadcasterName(value);
  };
  return (
    <div>
      <Form.Control
        className="w-200 d-inline-block"
        type="text"
        name={props.id}
        placeholder={props.placeholder}
        value={broadcasterName}
        id={props.id}
        disabled={true}
      />
      <Button
        variant="primary"
        type="button"
        as="input"
        value="選択"
        className="m-10"
        onClick={() => {
          setShowModal(true);
        }}
      />
      <Button
        variant="danger"
        type="button"
        as="input"
        value="入力クリア"
        className="m-10"
        onClick={() => {
          changeBroadcasterName('');
        }}
      />
      <SelectBroadcasterModal show={showModal} setShow={setShowModal} nextStep={changeBroadcasterName} />
    </div>
  );
}

export default InputBroadcasterNameWidget;
