import * as XLSX from 'xlsx';
import { Button } from 'react-bootstrap';
import ExportObject from '../../export_report/ExportObject';
import { Ref } from 'react';

interface Props {
  data: any[];
  hiddenDLButtonRef: Ref<HTMLButtonElement>;
}

const getNowDateTime = () => {
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};

const ExportXlsxData: React.FC<Props> = ({ data, hiddenDLButtonRef }) => {
  const downloadCSV = () => {
    const filename = `${getNowDateTime()}利用報告一覧.xlsx`;
    const exportReports = new ExportObject(data);
    exportReports.convert();
    const exportData = exportReports.exports;
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelData = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
    const blob = new Blob([excelData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  return (
    <Button ref={hiddenDLButtonRef} onClick={(e) => downloadCSV()} className="d-none">
      ダウンロード
    </Button>
  );
};

export default ExportXlsxData;
