import DataTable, { TableColumn, defaultThemes } from 'react-data-table-component';

interface GridTableProps {
  columns: Array<TableColumn<any>>;
  data: any[];
  selectableRows?: boolean;
  clearSelectedRows?: boolean;
  selectableRowDisabled?: any;
  onSelectedRowsChange?: ({ selectedRows }: { selectedRows: any }) => void;
  fontSize: string;
  autoScroll?: boolean;
  striped?: boolean;
  pagination?: boolean;
  resetPagination?: boolean;
}

const GridTable: React.FunctionComponent<GridTableProps> = ({
  columns,
  data,
  selectableRows = false,
  clearSelectedRows = false,
  selectableRowDisabled = undefined,
  onSelectedRowsChange = undefined,
  fontSize,
  autoScroll = true,
  striped = false,
  pagination = true,
  resetPagination = false,
}) => {
  const BORDER_STYLE = 'solid';
  const BORDER_WIDTH = '1px';
  const BORDER_COLOR = defaultThemes.default.divider.default;
  const TABLE_FONT_SIZE = fontSize;
  const MARGIN = '8px';
  const customStyles = {
    table: {
      style: {
        borderBottomStyle: BORDER_STYLE,
        borderBottomWidth: BORDER_WIDTH,
        borderBottomColor: BORDER_COLOR,
      },
    },
    header: {
      style: {},
    },
    headRow: {
      style: {
        borderTopStyle: BORDER_STYLE,
        borderTopWidth: BORDER_WIDTH,
        borderTopColor: BORDER_COLOR,
        borderLeftStyle: BORDER_STYLE,
        borderLeftWidth: BORDER_WIDTH,
        borderLeftColor: BORDER_COLOR,
      },
    },
    headCells: {
      style: {
        borderRightStyle: BORDER_STYLE,
        borderRightWidth: BORDER_WIDTH,
        borderRightColor: BORDER_COLOR,
        marginLeft: MARGIN,
        fontSize: TABLE_FONT_SIZE,
      },
    },
    rows: {
      style: {
        borderLeftStyle: BORDER_STYLE,
        borderLeftWidth: BORDER_WIDTH,
        borderLeftColor: BORDER_COLOR,
      },
      stripedStyle: {
        backgroundColor: '#F2F2F2',
      },
    },
    cells: {
      style: {
        borderRightStyle: BORDER_STYLE,
        borderRightWidth: BORDER_WIDTH,
        borderRightColor: BORDER_COLOR,
        marginLeft: MARGIN,
        fontSize: TABLE_FONT_SIZE,
      },
    },
  };

  return (
    <>
      <DataTable
        className="mt-1"
        columns={columns}
        data={data}
        selectableRows={selectableRows}
        clearSelectedRows={clearSelectedRows}
        selectableRowDisabled={selectableRowDisabled}
        onSelectedRowsChange={onSelectedRowsChange}
        responsive={autoScroll}
        striped={striped}
        pagination={pagination}
        paginationResetDefaultPage={resetPagination}
        customStyles={customStyles}
        noDataComponent=""
      />
    </>
  );
};

export default GridTable;
