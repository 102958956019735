import { FieldTemplateProps } from '@rjsf/utils';

const CustomBooleanFieldTemplate = (props: FieldTemplateProps): JSX.Element => {
  const { classNames, style, help, description, errors, children } = props;

  return (
    <div className={classNames} style={style}>
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
};

export default CustomBooleanFieldTemplate;
