import { Button, Modal, Table, Form } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import BroadcasterData from './broadcasterData.json';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  nextStep: (broadcasterName: string) => void;
}
const SelectModal: React.FC<ModalProps> = ({ show, setShow, nextStep }) => {
  const [broadcasterName, setBroadcasterName] = useState<string>('');
  const [freeBroadcasterName, setFreeBroadcasterName] = useState<string>('');
  const [nextButtonDisabled, setNextButtonDisabled] = useState<boolean>(true);
  const [selectIndex, setSelectIndex] = useState<string>('');

  const setSelect = (event: any, trIndex: number, tdIndex: number) => {
    const text = (event.target as HTMLElement).textContent;
    setBroadcasterName(text ? text : '');
    setNextButtonDisabled(text ? false : true);
    setSelectIndex(text ? String(trIndex) + '-' + String(tdIndex) : '');
  };

  useEffect(() => {
    if (!show) return;
    setBroadcasterName('');
    setNextButtonDisabled(true);
    setSelectIndex('');
  }, [show]);

  const selectItem = (selectName: string) => {
    nextStep(selectName);
    setShow(false);
  };

  return (
    <Modal
      show={show}
      size="lg"
      scrollable={true}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>放送局の選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        以下から選択してください。
        <Table className="small" bordered>
          <thead>
            <tr>
              <th className="p-1">地域区分</th>
              <th className="w-10p p-1">都道府県</th>
              <th colSpan={6}>放送局名/略号</th>
            </tr>
          </thead>
          <tbody>
            {BroadcasterData.data.map((broadcaster, index) => (
              <tr key={index}>
                {broadcaster.area && (
                  <td
                    className={broadcaster.area.bgcolor ? broadcaster.area.bgcolor + ' p-1 align-middle' : ' p-1 align-middle'}
                    rowSpan={broadcaster.area.rowspan ? broadcaster.area.rowspan : 1}
                    colSpan={broadcaster.area.colspan ? broadcaster.area.colspan : 1}
                  >
                    {broadcaster.area.name}
                  </td>
                )}
                {broadcaster.prefecture && (
                  <td className={broadcaster.prefecture.bgcolor ? broadcaster.prefecture.bgcolor + ' p-1 align-middle' : 'p-1 align-middle'}>
                    {broadcaster.prefecture.name}
                  </td>
                )}
                {broadcaster.broadcasters.map((item: any, itemIndex: number) => (
                  <td
                    key={itemIndex}
                    className={`w-14p p-1 align-middle ${item.name === '' ? 'bg-whitesmoke' : ''} ${
                      selectIndex === String(index) + '-' + String(itemIndex) ? 'alert-primary' : ''
                    }`}
                    rowSpan={item.rowspan ? item.rowspan : 1}
                    onClick={(event) => setSelect(event, index, itemIndex)}
                  >
                    {item.name}
                    {item.shortName && '(' + item.shortName + ')'}
                  </td>
                ))}
                <></>
              </tr>
            ))}
            <tr>
              <td colSpan={8}>
                <span style={{ color: 'red' }}>※上記にない放送局をご利用の場合は、以下のフォームに放送局名をご入力ください。</span>
              </td>
            </tr>
            <tr>
              <td colSpan={8}>
                <Form.Control
                  className="w-200 d-inline-block"
                  type="text"
                  value={freeBroadcasterName}
                  onChange={(event) => {
                    setFreeBroadcasterName(event.target.value);
                  }}
                />
                <Button
                  variant="primary"
                  type="button"
                  as="input"
                  value="決定"
                  className="m-10"
                  onClick={() => {
                    selectItem(freeBroadcasterName);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          disabled={nextButtonDisabled}
          variant="danger"
          onClick={() => {
            selectItem(broadcasterName);
          }}
        >
          選択
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
