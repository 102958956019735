import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api/CallManagerApi';
import reportApi from '../api/CallCreateReportApi';
import { getNextUrl, setSmallMediaCategorySelected } from '../utils';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useDispatch } from 'react-redux';
import SmallMediaCategory from '../components/InputSmallMediaCategory';
import HelpArea from '../components/HelpComponent';
import { MediaCategory } from '../interface/MediaCategory';
import {
  getRequiredBigMediaCategoryTmpById,
  getMediaCategoryTmpById,
  isHalfWritten,
  getRequiredHalfWrittenMediaCategoryById,
} from '../localStorageUtils';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

const RegisterSmallMediaCategory: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [mediaCategoryData, setMediaCategoryData] = useState<MediaCategory[]>([]);
  const [selectedMediaCategory, setSelectedMediaCategory] = useState<string>('');
  const [bigMediaCategory, setBigMediaCategory] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [helps, setHelps] = useState<any[]>([]);
  const { showBoundary } = useErrorBoundary();

  const nextPage = (): void => {
    const nextPage = getNextUrl(location.pathname);
    navigate(nextPage);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        const profile: BaseUserInfo = await api.getProfile();
        setUserInfo(profile);
        const prevBigMediaCategory = getRequiredBigMediaCategoryTmpById(profile._id);
        setBigMediaCategory(prevBigMediaCategory);
        const data: MediaCategory[] = await reportApi.getMediaCategory(prevBigMediaCategory);
        setMediaCategoryData(setSmallMediaCategorySelected(data[0].children, selectedMediaCategory));

        const prevMediaCategory = getMediaCategoryTmpById(profile._id);
        if (prevMediaCategory) {
          setSelectedMediaCategory(prevMediaCategory);
          return;
        }

        if (isHalfWritten(profile._id)) {
          // 途中復帰の場合
          const mediaCategoryStr = getRequiredHalfWrittenMediaCategoryById(profile._id);
          setSelectedMediaCategory(mediaCategoryStr);
        }
      } catch (e) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <SmallMediaCategory
              bigMediaCategory={bigMediaCategory}
              movePage={nextPage}
              mediaCategory={mediaCategoryData}
              selectedMediaCategoryByUpperLayer={selectedMediaCategory}
              userInfo={userInfo}
              setDisplayHelpData={setHelps}
            />
          </Col>
          <HelpArea helps={helps} />
        </Row>
      </Container>
    </>
  );
};
export default RegisterSmallMediaCategory;
