import { Button } from 'react-bootstrap';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

interface Props {
  enabledBack: boolean;
  enabledNext: boolean;
  onClickBack: () => void;
  onClickNext: () => void;
}

const ArrowButtons: React.FC<Props> = ({ enabledBack, enabledNext, onClickBack, onClickNext }) => {
  return (
    <>
      <Button variant="outline-secondary" type="button" className="m-10" onClick={onClickBack} disabled={!enabledBack}>
        <IoIosArrowBack style={{ marginBottom: '5px' }} />
      </Button>
      <Button variant="outline-secondary" type="button" className="m-10" onClick={onClickNext} disabled={!enabledNext}>
        <IoIosArrowForward style={{ marginBottom: '5px' }} />
      </Button>
    </>
  );
};

export default ArrowButtons;
