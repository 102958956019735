import { Container, Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api/CallUserApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { refreshAll } from '../../store/filterReducer';
import { useDispatch } from 'react-redux';
import { useErrorBoundary } from 'react-error-boundary';
import { User } from '../interface/User';

const SelectUser: React.FunctionComponent = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const [userId, setUserId] = useState<string>('');
  const [pass, setPass] = useState<string>('');
  const [isDisplayLoginModal, setIsDisplayLoginModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getUsers = async () => {
      const responseUsers: User[] = await api.getUsers();
      setAllUsers(responseUsers);
      setUsers(responseUsers);
    };
    getUsers();
  }, []);

  const showLoginModal = async (showFlag: boolean, userId: string) => {
    const noPasswordLogin = async (userId: string) => {
      try {
        await api.Login(userId, 'null');
        // 一覧の検索条件初期化
        dispatch(refreshAll());
        navigate('/users/reports');
      } catch (e: any) {
        if (e.response.status === 401 || e.response.status === 400) {
          toast.error('ログインできませんでした。', toastConfig());
          return;
        }
        showBoundary(e.response);
      }
    };
    if (showFlag) {
      setUserId(userId);
      setIsDisplayLoginModal(true);
    } else {
      await noPasswordLogin(userId);
    }
  };

  const login = async () => {
    try {
      await api.Login(userId, pass);
      setIsDisplayLoginModal(false);
      // 一覧の検索条件初期化
      dispatch(refreshAll());
      navigate('/users/reports');
    } catch (e: any) {
      if (e.response.status === 401 || e.response.status === 400) {
        toast.error('ログインできませんでした。', toastConfig());
        return;
      }
      showBoundary(e.response);
    }
  };

  const insLogout = () => {
    const logout = async () => {
      await api.InstitutionLogout();
      navigate('/users/login');
    };
    logout();
  };

  const search = (searchWord: string) => {
    setUsers(allUsers.filter((user) => user.displayName.includes(searchWord)));
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div>
              <Link to="/users/create" className="m-10">
                <Button variant="primary" as="input" type="button" value="新規ユーザー作成" className="m-10" />
              </Link>
              <Button variant="danger" as="input" type="button" value="ログアウト" className="m-10" onClick={() => insLogout()} />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={{ span: 4, offset: 4 }}>
            <Form.Group controlId="searchName">
              <Form.Label>名前で絞り込み</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  search(event.target.value);
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered size="sm">
              <thead>
                <tr>
                  <th>お名前</th>
                  <th className="w-25"></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td className="text-center align-middle">{user.displayName}</td>
                    <td>
                      <Button
                        variant="primary"
                        as="input"
                        type="button"
                        value="ログイン"
                        className="m-10"
                        onClick={() => {
                          showLoginModal(user.private, user._id);
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <Modal
        show={isDisplayLoginModal}
        onHide={() => {
          setIsDisplayLoginModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>ユーザーログイン</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>パスワード</Form.Label>
            <Form.Control
              type="password"
              onChange={(event) => {
                setPass(event.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setIsDisplayLoginModal(false);
            }}
          >
            閉じる
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              login();
            }}
          >
            ログイン
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default SelectUser;
