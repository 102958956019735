import { unexpectedExceptionClient, expectedExceptionClient } from './AxiosClientProvider';
import { Report } from '../interface/Report';
import { ManagerAdminUserInfo } from '../interface/ManagerAdminUserInfo';
import { Institution } from '../interface/Institution';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { ClassInfo } from '../interface/ClassInfo';
import { Media } from '../interface/Media';
import { Work } from '../interface/Work';
import { OwnerUserInfo } from '../interface/OwnerUserInfo';
import { Comment } from '../interface/Comment';

const LOGIN_API = '/v1/admin/login';
const GET_REPORTS_API = '/v1/admin/reports';
const GET_OWNERS_API = '/v1/admin/owners';
const GET_MANAGERS_API = '/v1/admin/managers/';
const GET_INSTITUTIONS_API = '/v1/admin/institutions';
const BULK_DELETE_API = '/v1/admin/reports/bulk-delete';
const BULK_UPDATE_API = '/v1/admin/reports/bulk-update';
const GET_REPORT_API = '/v1/admin/reports/';
const GET_PROFILE_API = '/v1/admin/profile';
const GET_PROFILE_MATCH_PASSWORD_API = '/v1/admin/profile/match-password';
const LOGOUT_API = '/v1/admin/logout';
const MARK_AS_NO_SUBMISSION_API = '/v1/admin/institutions/markAsNoSubmission/';
const MANAGER_DETAILED_SEARCH_API = '/v1/admin/managers/detailedSearch';

const Login = async (_id: string, password: string): Promise<any> => {
  return await expectedExceptionClient.post(LOGIN_API, { _id, password }).catch((error) => {
    throw error;
  });
};

const getReports = async (phase: string, institution: string, owner: string): Promise<Report[]> => {
  return await unexpectedExceptionClient
    .get(GET_REPORTS_API, { params: { phase, institution, owner } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getOwners = async (): Promise<OwnerUserInfo[]> => {
  const results = await unexpectedExceptionClient.get(GET_OWNERS_API);
  return results.data;
};

const getManagers = async (): Promise<any> => {
  const results = await unexpectedExceptionClient.get(GET_MANAGERS_API);
  return results.data;
};

const getInstitutions = async (owner: string): Promise<Institution[]> => {
  const results = await unexpectedExceptionClient.get(GET_INSTITUTIONS_API, {
    params: { owner },
  });
  return results.data;
};

const bulkDelete = async (ids: string[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_DELETE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkUpdate = async (ids: BulkUpdatePhase[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_UPDATE_API, ids).catch((error) => {
    throw error;
  });
};

const getReport = async (id: string): Promise<Report> => {
  return await unexpectedExceptionClient
    .get(GET_REPORT_API + id)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const postReport = async (classInfo: ClassInfo, media: Media, work: Work, phase: string, user: string): Promise<any> => {
  return await expectedExceptionClient.post(GET_REPORTS_API, { phase, classInfo, media, work, user }).catch((error) => {
    throw error;
  });
};

const getProfile = async (): Promise<ManagerAdminUserInfo> => {
  return await unexpectedExceptionClient
    .get(GET_PROFILE_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const Logout = async (): Promise<any> => {
  return await unexpectedExceptionClient.post(LOGOUT_API).catch((error) => {
    throw error;
  });
};

const deleteReport = async (id: string): Promise<any> => {
  return await expectedExceptionClient.delete(GET_REPORT_API + id).catch((error) => {
    throw error;
  });
};

const updatePhase = async (_id: string, phase: string, comment: Comment | null): Promise<Report> => {
  return await expectedExceptionClient
    .put(GET_REPORT_API + _id, { _id, phase, comment })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updatePassword = async (password: string): Promise<any> => {
  return await expectedExceptionClient.put(GET_PROFILE_API, { password: password }).catch((error) => {
    throw error;
  });
};

const putProfile = async (name: string, email: string): Promise<ManagerAdminUserInfo> => {
  return await expectedExceptionClient
    .put(GET_PROFILE_API, { name, email })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const isMatchOldPassword = async (password: string): Promise<boolean> => {
  return await expectedExceptionClient
    .post(GET_PROFILE_MATCH_PASSWORD_API, { password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateReport = async (id: string, classInfo: ClassInfo, media: Media, work: Work): Promise<any> => {
  return await expectedExceptionClient.put(GET_REPORT_API + id, { classInfo, media, work }).catch((error) => {
    throw error;
  });
};

const markAsNoSubmission = async (id: string): Promise<any> => {
  const results = await unexpectedExceptionClient.put(MARK_AS_NO_SUBMISSION_API + id);
  return results.data;
};

const managerDetailedSearch = async (params: any): Promise<any> => {
  const results = await unexpectedExceptionClient.get(MANAGER_DETAILED_SEARCH_API, { params });
  return results.data;
};

const exportedObject = {
  Login,
  getReports,
  getOwners,
  getManagers,
  getInstitutions,
  bulkDelete,
  bulkUpdate,
  getReport,
  postReport,
  getProfile,
  Logout,
  deleteReport,
  updatePhase,
  updatePassword,
  putProfile,
  isMatchOldPassword,
  updateReport,
  markAsNoSubmission,
  managerDetailedSearch,
};

export default exportedObject;
