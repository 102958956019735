import { useState } from 'react';
import { Row, Col, Container, Form, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../../utils';
import { User } from '../../interface/User';
import { Download } from 'react-bootstrap-icons';
import { FaPlus } from 'react-icons/fa';
import { parse } from 'papaparse';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  createUsers: (parseUsers: User[]) => void;
}

const CreateUsersModal: React.FC<ModalProps> = ({ show, setShow, createUsers }) => {
  const [usersFile, setUsersFile] = useState<File>();
  const FORMAT_HEADER = 'ユーザー名,表示名\r\n';

  const clickDownload = () => {
    const downloadLink = document.createElement('a');
    downloadLink.download = 'sample_format.csv';
    downloadLink.href = URL.createObjectURL(new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), FORMAT_HEADER], { type: 'text/csv' }));
    downloadLink.click();
    downloadLink.remove();
  };

  const clickCreateUsers = async () => {
    const parser = (file: File) => {
      return new Promise((resolve, reject) => {
        parse(file, {
          complete: (results: any) => {
            resolve(results?.data);
          },
          error: () => {
            reject(new Error());
          },
          skipEmptyLines: true,
        });
      });
    };
    try {
      if (!usersFile) throw Error;
      const parseUsers: any = await parser(usersFile);
      parseUsers.shift();
      const fileRowCount = parseUsers.length;
      if (!fileRowCount) throw Error;
      if (fileRowCount !== new Set(parseUsers.map((item) => item[0])).size || fileRowCount !== new Set(parseUsers.map((item) => item[1])).size) {
        // 入力数とSet化後のサイズが異なれば重複している
        throw Error('ユーザー名もしくは表示名が他のユーザーと重複しています。');
      }
      const results: User[] = [];
      for (const parseUser of parseUsers) {
        if (parseUser.length !== 2 || !parseUser[0] || !parseUser[1]) throw Error;
        results.push({
          _id: '',
          name: parseUser[0],
          displayName: parseUser[1],
          private: false,
        });
      }
      createUsers(results);
    } catch (e: any) {
      const errorMessage = e.message ? e.message : 'ユーザーの作成に失敗しました。アップロードしたファイルを確認してください。';
      toast.error(errorMessage, toastConfig());
      setUsersFile(undefined);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>ユーザー一括作成</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row style={{ alignItems: 'center', height: '90px' }}>
            <Col>1. ボタンからフォーマットをダウンロード</Col>
            <Col>
              <Button variant="primary" type="button" className="m-10" onClick={clickDownload} style={{ marginTop: '7px' }}>
                <Download size={20} style={{ marginRight: '5px', marginBottom: '5px' }} />
                ダウンロード
              </Button>
            </Col>
          </Row>
          <Row style={{ height: '60px' }}>
            <Col>2. 作成するユーザーの情報を入力</Col>
          </Row>
          <Row style={{ height: '60px' }}>
            <Col>3. 右のボタンからフォーマットをアップロード</Col>
            <Col>
              <Form.Group controlId="formFile">
                <Form.File accept=".csv" onChange={(event) => setUsersFile(event.target.files[0])} />
              </Form.Group>
            </Col>
          </Row>
          <Row style={{ fontSize: '13px', height: '100px' }}>
            <Col>
              <div style={{ color: 'red' }}>
                注1)　本機能は、複数のユーザーを追加する機能です。
                <br />
                <u style={{ marginLeft: '37px' }}>既存のユーザー情報を更新することはできません</u>
                ので、ご注意ください。
                <br />
                <br />
                注2)　作成後のユーザー情報を一括出力することはできませんので、ご注意ください。
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ textAlign: 'right' }}>
        <p>
          一括作成ボタンをクリックして完了
          <Button variant="success" type="button" className="m-10" onClick={clickCreateUsers}>
            <FaPlus style={{ marginRight: '5px', marginBottom: '5px' }} />
            一括作成
          </Button>
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default CreateUsersModal;
