import { FieldTemplateProps } from '@rjsf/utils';

const CustomFieldTemplate = (props: FieldTemplateProps): JSX.Element => {
  const { id, classNames, style, label, help, required, description, errors, children } = props;
  return (
    <div className={classNames} style={style}>
      <label htmlFor={id}>{label}</label>
      {required && <span className="badge badge-danger ml-1">必須</span>}
      {description}
      {children}
      {errors}
      {help}
    </div>
  );
};

export default CustomFieldTemplate;
