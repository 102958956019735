import { FieldTemplateProps } from '@rjsf/utils';
import { Card } from 'react-bootstrap';
const CustomFieldTemplate = (props: FieldTemplateProps): JSX.Element => {
  const { id, classNames, style, label, help, description, errors, children } = props;

  return (
    <Card>
      <Card.Body>
        <div className={classNames} style={style}>
          <label htmlFor={id}>{label}</label>
          {description}
          {children}
          {errors}
          {help}
        </div>
      </Card.Body>
    </Card>
  );
};

export default CustomFieldTemplate;
