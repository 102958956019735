import { Col, Row, Container } from 'react-bootstrap';

const Footer: React.FC = () => {
  return (
    <Container className="mt-4 mb-4">
      <Row>
        <Col className="text-black-50 text-center">
          <small>Copyright © 一般社団法人 授業目的公衆送信補償金等管理協会 All Rights Reserved.</small>
        </Col>
      </Row>
    </Container>
  );
};

export default Footer;
