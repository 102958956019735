import React from 'react';

interface Props {
  helps: any[];
}

const HelpArea: React.FC<Props> = React.memo(({ helps }) => {
  return (
    <>
      {helps.map((Component, index) => (
        <Component key={index} />
      ))}
    </>
  );
});

export default HelpArea;
