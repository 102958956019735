import { Col } from 'react-bootstrap';
interface Props {
  helpPaddingTop?: any;
  helps: any[];
}

const HelpArea: React.FC<Props> = ({ helps, helpPaddingTop }) => {
  return (
    <>
      <Col md={3} lg={3} className="pl-0 pr-0 help-area" style={helpPaddingTop}>
        {helps.map((Component, index) => (
          <Component key={index} />
        ))}
      </Col>
    </>
  );
};

export default HelpArea;
