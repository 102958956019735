import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import Form from '@rjsf/bootstrap-4';
import { Ref, forwardRef, useEffect, useCallback } from 'react';
import localizer from 'ajv-i18n';
import { getErrorMessage } from '../utils';
import React from 'react';

interface apiDataProps {
  schema: any;
  uiSchema: any;
  isRequiredValidation: boolean;
  formData: any;
  idPrefix: string;
  setFormData: (formData: any, type: string) => void;
}

const InputForm = React.memo(
  forwardRef<HTMLButtonElement, apiDataProps>((props, ref: Ref<HTMLButtonElement>) => {
    const uiSchema: UiSchema = props.uiSchema;
    const schema: RJSFSchema = props.schema;
    const validator = customizeValidator({}, localizer.ja);

    const keydownEnter = useCallback((event: any) => {
      if (event && event.keyCode === 13) {
        event.preventDefault();
      }
    }, []);

    useEffect(() => {
      document.addEventListener('keydown', keydownEnter, false);
      return () => {
        document.removeEventListener('keydown', keydownEnter);
      };
    }, []);

    const preventNumberChange = (event: any) => {
      if ((document.activeElement as HTMLInputElement)?.type === 'number') {
        event.target.blur();
      }
    };

    useEffect(() => {
      document.addEventListener('wheel', preventNumberChange, false);
      return () => {
        document.removeEventListener('wheel', preventNumberChange);
      };
    }, []);

    const transformErrors = (errors: any, uiSchema: any) => {
      return errors.map((error: any) => {
        const key = error.property.replace('.', '');
        const title = uiSchema[key]['ui:title'];
        const errorMessages = getErrorMessage(error, title);
        error.message = errorMessages.message;
        error.stack = errorMessages.stack;
        return error;
      });
    };

    return (
      <Form
        schema={schema}
        uiSchema={uiSchema}
        formData={props.formData}
        onChange={({ formData }) => props.setFormData(formData, 'change')}
        onSubmit={({ formData }) => props.setFormData(formData, 'submit')}
        omitExtraData={true}
        noHtml5Validate={true}
        noValidate={!props.isRequiredValidation}
        validator={validator}
        transformErrors={transformErrors}
        idPrefix={props.idPrefix}
        focusOnFirstError={true}
      >
        <button ref={ref} type="submit" style={{ display: 'none' }} />
      </Form>
    );
  })
);

export default InputForm;
