import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Alert } from 'react-bootstrap';
import { useState } from 'react';
import api from '../api/CallListApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLoginUrl } from '../utils';

function IsbnInputWidget(props: WidgetProps) {
  const [isbnCode, setIsbnCode] = useState<string>(props.value ? props.value : '');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const searchIsbn = () => {
    const replaceIsbn = isbnCode.replace(/-/g, '');
    setIsbnCode(replaceIsbn);
    const getIsbn = async () => {
      try {
        dispatch(startLoading());
        const data = await api.getIsbn(replaceIsbn);
        setShowAlert(false);
        setErrorMsg('');
        props.formContext.updateIsbnData(data, replaceIsbn);
      } catch (e: any) {
        if (e.response.status === 401) {
          const url = getLoginUrl(location.pathname);
          navigate(url);
        } else if (e.response.status === 404) {
          setShowAlert(true);
          setErrorMsg('ISBNコードに一致する書籍が見つかりませんでした。お手数ですが手入力をお願いします。');
        } else {
          setShowAlert(true);
          setErrorMsg('エラーが発生しました。');
        }
      } finally {
        dispatch(stopLoading());
      }
    };
    getIsbn();
  };

  const changeIsbnCode = (value: string) => {
    props.onChange(value ? value : undefined);
    setIsbnCode(value);
  };

  return (
    <div>
      <Alert variant="danger" show={showAlert}>
        {errorMsg}
      </Alert>
      <Form.Control
        className="w-200 d-inline-block"
        type="text"
        name={props.id}
        placeholder={props.placeholder}
        value={isbnCode}
        id={props.id}
        onChange={(event) => changeIsbnCode(event.target.value)}
      />
      <Button
        variant="primary"
        type="button"
        as="input"
        value="検索"
        className="m-10"
        onClick={() => {
          searchIsbn();
        }}
      />
    </div>
  );
}

export default IsbnInputWidget;
