import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallOwnerApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { PHASE } from '../enum/PhaseEnum';
import ShowWorkConfirm from '../components/ShowWorkConfirm';
import SelectInstitutionModal from '../components/modal/SelectInstitutionModal';
import { refreshAll } from '../../store/filterReducer';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useErrorBoundary } from 'react-error-boundary';
import {
  getRequiredLessonTmpById,
  getRequiredMediaCategoryTmpById,
  getSubBigMediaCategoryTmpById,
  getRequiredMediaTmpById,
  getRequiredWorksTmpById,
  removeAllTmpDataById,
} from '../localStorageUtils';
import Axios from 'axios';

const RegisterWorkConfirm: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const [showSelectInstitutionModal, setShowSelectInstitutionModal] = useState<boolean>(false);
  const [institutions, setInstitutions] = useState<any[]>([]);
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  const showSelectModal = () => {
    const getData = async () => {
      const institutions = await api.getInstitutions();
      setInstitutions(institutions);
      setShowSelectInstitutionModal(true);
    };
    getData();
  };

  const save = (selectInstitution: string) => {
    const saveReports = async () => {
      dispatch(startLoading());
      const defaultUser = await api.getDefaultUser(selectInstitution);
      try {
        const lesson = getRequiredLessonTmpById(userInfo._id);
        const mediaCategoryStr = getRequiredMediaCategoryTmpById(userInfo._id);
        const subBigMediaCategoryStr = getSubBigMediaCategoryTmpById(userInfo._id);
        const mediaInfo = getRequiredMediaTmpById(userInfo._id);
        const works = getRequiredWorksTmpById(userInfo._id);
        const mediaCategoryArray = mediaCategoryStr.split(',');
        if (subBigMediaCategoryStr) mediaCategoryArray.unshift(subBigMediaCategoryStr);
        const media = { categories: mediaCategoryArray, info: mediaInfo };
        for (const work of works) {
          const workInfo = { subCategory: work.subCategory, info: work.formData };
          await api.postReport(lesson, media, workInfo, PHASE.MANAGER_CONFIRMED, defaultUser);
        }
        navigate('/owner/reports');
      } catch (e: any) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
        if (e.response.status === 400) {
          toast.error('利用報告を作成できませんでした。', toastConfig());
          navigate('/owner/reports');
        } else {
          showBoundary(e.response);
        }
      } finally {
        dispatch(stopLoading());
        removeAllTmpDataById(userInfo._id);
        // 一覧の検索条件初期化
        dispatch(refreshAll());
      }
    };
    saveReports();
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <ShowWorkConfirm userInfo={userInfo} saveReports={showSelectModal} />
        <SelectInstitutionModal
          show={showSelectInstitutionModal}
          setShow={setShowSelectInstitutionModal}
          institutions={institutions}
          changeInstitution={save}
        />
      </Container>
    </>
  );
};
export default RegisterWorkConfirm;
