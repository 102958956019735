import { useEffect, useState } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

interface NotificationMailComponentProps {
  api: any;
  accountString: string;
  phaseString: string;
}

const NotificationMailComponent: React.FC<NotificationMailComponentProps> = ({ api, accountString, phaseString }) => {
  const DAYS_STRINGS = [
    { en: 'monday', jp: '月' },
    { en: 'tuesday', jp: '火' },
    { en: 'wednesday', jp: '水' },
    { en: 'thursday', jp: '木' },
    { en: 'friday', jp: '金' },
    { en: 'saturday', jp: '土' },
    { en: 'sunday', jp: '日' },
  ];
  const [isNotificationMail, setIsNotificationMail] = useState<boolean>(false);
  const [notificationDays, setNotificationDays] = useState<{}>({
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  });
  const { showBoundary } = useErrorBoundary();

  const clickSaveButton = async () => {
    try {
      await api.putNotificationDays(notificationDays);
      toast.success('メール通知の変更が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        toast.error('メール通知の変更に失敗しました。', toastConfig());
        return;
      }
      showBoundary(e.response);
    }
  };

  const changeRadioButton = () => {
    setIsNotificationMail((prev) => !prev);
    setNotificationDays({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
  };

  useEffect(() => {
    (async () => {
      const response = await api.getProfile();
      setIsNotificationMail(Object.values(response.notificationDays).some((value) => value === true));
      setNotificationDays(response.notificationDays);
    })();
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>メール通知の設定</Card.Title>
        確認対象の利用報告（※）が届いている場合に、
        <br />
        {accountString}の連絡先メールアドレスへ通知を送信します。
        <br />
        <br />
        <div className="w-100 text-muted lh-1">（※）ステータス「{phaseString}」の利用報告</div>
        <br />
        <Form.Group>
          <Form.Check id="invalid" type="radio" label="通知しない" checked={!isNotificationMail} onChange={changeRadioButton} />
          <Form.Check id="valid" type="radio" label="特定の曜日に通知する（複数選択可）" checked={isNotificationMail} onChange={changeRadioButton} />
          <Form.Group className="form-inline">
            <div>　</div>
            {DAYS_STRINGS.map((day) => (
              <Form.Check
                id={day.en}
                type="checkbox"
                label={day.jp + '　'}
                disabled={!isNotificationMail}
                checked={notificationDays[day.en]}
                onChange={(event) => {
                  setNotificationDays((prev) => {
                    const newDaysOfWeek = { ...prev };
                    newDaysOfWeek[day.en] = event.target.checked;
                    return newDaysOfWeek;
                  });
                }}
              />
            ))}
          </Form.Group>
          <div className="w-100 text-muted lh-1">　選択された曜日の午前9時に、確認対象の利用報告を検索・通知します。</div>
        </Form.Group>
        <div className="ta-right">
          <Button variant="primary" onClick={clickSaveButton}>
            保存
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
export default NotificationMailComponent;
