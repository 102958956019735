import { CaretRightFill } from 'react-bootstrap-icons';
import { ListGroup } from 'react-bootstrap';

interface Props {
  DisplayUserInfoName: string;
  isDisplayUserInfo: boolean;
  isDisplayChangePassword: boolean;
  isDisplayNotificationMail?: boolean;
  isDisplayNotificationMailItem?: boolean;
  selectDisplayInfo: () => void;
  selectChangePassword: () => void;
  selectNotificationMail?: () => void;
}
const ChangeUserInfoListGroup: React.FC<Props> = ({
  DisplayUserInfoName,
  isDisplayUserInfo,
  isDisplayChangePassword,
  isDisplayNotificationMail,
  isDisplayNotificationMailItem,
  selectDisplayInfo,
  selectChangePassword,
  selectNotificationMail,
}): JSX.Element => {
  return (
    <ListGroup>
      <ListGroup.Item action active={isDisplayUserInfo} onClick={selectDisplayInfo}>
        {DisplayUserInfoName}
        <CaretRightFill />
      </ListGroup.Item>
      <ListGroup.Item action active={isDisplayChangePassword} onClick={selectChangePassword}>
        パスワードを変更
        <CaretRightFill />
      </ListGroup.Item>
      {isDisplayNotificationMailItem && (
        <ListGroup.Item action active={isDisplayNotificationMail} onClick={selectNotificationMail}>
          メール通知設定
          <CaretRightFill />
        </ListGroup.Item>
      )}
    </ListGroup>
  );
};

export default ChangeUserInfoListGroup;
