import { Form } from 'react-bootstrap';

interface ReportsCheckboxModeComponentProps {
  selectableRowsVisibleOnly: boolean;
  handleChangeFunction: () => void;
}

const ReportsCheckboxModeComponent: React.FC<ReportsCheckboxModeComponentProps> = ({ selectableRowsVisibleOnly, handleChangeFunction }) => {
  return (
    <Form.Group>
      <Form.Check
        type="switch"
        id="custom-switch"
        label={<div style={{ marginTop: '1px' }}>{'選択範囲' + (selectableRowsVisibleOnly ? '［表示中の報告］' : '［全ページの報告］')}</div>}
        checked={!selectableRowsVisibleOnly}
        onChange={handleChangeFunction}
        style={{ fontSize: '13px', marginBottom: '-20px' }}
      />
    </Form.Group>
  );
};

export default ReportsCheckboxModeComponent;
