import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import { useState } from 'react';
import api from '../api/CallListApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import Modal from './modal/SelectAuthorizedTextBookModal';
import Autosuggest from 'react-autosuggest';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLoginUrl } from '../utils';

function AuthorizedTextbookWidget(props: WidgetProps) {
  const [imprintNumber, setImprintNumber] = useState<string>('');
  const [imprintName, setImprintName] = useState<string>('');
  const [textSymbol, setTextSymbol] = useState<string>('');
  const [textNumber, setTextNumber] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemList, setItemList] = useState<any[]>([]);
  const [textSymbolList, setTextSymbolList] = useState<string[]>([]);
  const [isSpecialEducation, setIsSpecialEducation] = useState<boolean>(false);
  const [specialTextNumber, setSpecialTextNumber] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const search = () => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        const submitTextNumber: string = isSpecialEducation ? specialTextNumber + '-' + textNumber : textNumber;
        const data = await api.getAuthorizedTextbook(imprintNumber, textSymbol, submitTextNumber);
        notDisplayError();
        if (data.length === 1) {
          props.formContext.updateTextBook(data[0], imprintName);
        } else if (data.length > 1) {
          setItemList(data);
          setShowModal(true);
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          const url = getLoginUrl(location.pathname);
          navigate(url);
        } else if (e.response.status === 400 || e.response.status === 404) {
        } else {
          displayErrorMessage('エラーが発生しました。');
        }
      } finally {
        dispatch(stopLoading());
      }
    };

    if (isAllEntered()) {
      notDisplayError();
      getData();
    } else {
      displayErrorMessage('以下の項目全てを入力してから検索ボタンを押してください。');
    }
  };

  const isAllEntered = () => {
    if (isSpecialEducation) return imprintNumber !== '' && imprintName !== '' && textSymbol !== '' && textNumber !== '' && specialTextNumber !== '';
    return imprintNumber !== '' && imprintName !== '' && textSymbol !== '' && textNumber !== '';
  };

  const searchImprintName = async () => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        const result = await api.getImprintName(imprintNumber);
        notDisplayError();
        setImprintName(result.imprintNameAbbr);
        props.formContext.updateImprintName(result.imprintName);
      } catch (e: any) {
        if (e.response.status === 401) {
          const url = getLoginUrl(location.pathname);
          navigate(url);
        } else if (e.response.status === 400 || e.response.status === 404) {
          setImprintName('');
          props.formContext.updateImprintName('');
        } else {
          displayErrorMessage('エラーが発生しました。');
        }
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  };

  const setSelectedItem = (item: any) => {
    setShowModal(false);
    props.formContext.updateTextBook(item, imprintName);
  };
  const onSuggestionsFetchRequested = ({ value }) => {
    const getData = async () => {
      try {
        const data = await api.getTextSymbol(value);
        notDisplayError();
        setTextSymbolList(data);
      } catch (e: any) {
        if (e.response.status === 401) {
          const url = getLoginUrl(location.pathname);
          navigate(url);
        } else if (e.response.status === 400 || e.response.status === 404) {
        } else {
          displayErrorMessage('エラーが発生しました。');
        }
      }
    };
    getData();
  };

  const displayErrorMessage = (msg: string) => {
    setShowAlert(true);
    setErrorMsg(msg);
  };

  const notDisplayError = () => {
    setShowAlert(false);
    setErrorMsg('');
  };

  const onSuggestionsClearRequested = () => {
    setTextSymbolList([]);
  };

  const getSuggestionValue = (suggestion: string) => suggestion;

  const renderSuggestion = (suggestion: string) => (
    // eslint-disable-next-line
    <a className="dropdown-item" href="#">
      {suggestion}
    </a>
  );

  const changeSuggest = (event, { newValue }) => {
    setTextSymbol(newValue);
  };

  const inputProps = {
    placeholder: '例)数Ⅰ',
    value: textSymbol,
    onChange: changeSuggest,
  };

  const changeSpecialEducation = (event) => {
    setIsSpecialEducation(event.target.checked);
  };

  return (
    <div>
      <Form.Row>
        <Col md={12}>
          <Alert variant="danger" show={showAlert}>
            {errorMsg}
          </Alert>
        </Col>
        <Col md={6} lg={2}>
          <label htmlFor="imprintNumber_id">発行者番号</label>
          <Form.Control
            type="text"
            id="imprintNumber_id"
            placeholder="例)2"
            onChange={(event) => {
              setImprintNumber(event.target.value);
            }}
            onBlur={() => searchImprintName()}
          />
        </Col>
        <Col md={6} lg={2}>
          <label htmlFor="imprintName_id">発行者略称</label>
          <Form.Control
            type="text"
            id="imprintName_id"
            placeholder="例)東書"
            readOnly={true}
            onChange={(event) => {
              setImprintName(event.target.value);
            }}
            value={imprintName}
          />
        </Col>
        <Col md={6} lg={2}>
          <label htmlFor="textSymbol_id">教科書記号</label>
          <Autosuggest
            suggestions={textSymbolList}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
            theme={{
              container: 'autosuggest',
              input: 'form-control',
              suggestionsContainer: 'dropdown open',
              suggestionsList: `dropdown-menu ${textSymbolList.length ? 'show' : ''}`,
              suggestion: '',
              suggestionFocused: 'active',
            }}
          />
        </Col>
        {isSpecialEducation && (
          <Col md={6} lg={2}>
            <label htmlFor="textNumberSpecial_id" className="special-number-label">
              特支教科書番号
            </label>
            <Form.Control
              type="text"
              id="textNumberSpecial_id"
              placeholder="例)C"
              value={specialTextNumber}
              onChange={(event) => {
                setSpecialTextNumber(event.target.value);
              }}
            />
          </Col>
        )}
        <Col md={6} lg={2}>
          <label htmlFor="textNumber_id">教科書番号</label>
          <Form.Control
            type="text"
            id="textNumber_id"
            placeholder="例)002"
            onChange={(event) => {
              setTextNumber(event.target.value);
            }}
          />
        </Col>
        <Col md={6} lg={2}>
          <Button
            variant="primary"
            type="button"
            as="input"
            value="検索"
            className="m-10"
            onClick={() => {
              search();
            }}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <Form.Check
            type="checkbox"
            id="special_education_id"
            label="特別支援学校の教科書"
            as="input"
            checked={isSpecialEducation}
            onChange={(event) => {
              changeSpecialEducation(event);
            }}
          />
        </Col>
      </Form.Row>
      <Modal show={showModal} setShow={setShowModal} itemList={itemList} nextStep={setSelectedItem} />
    </div>
  );
}

export default AuthorizedTextbookWidget;
