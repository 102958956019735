import DataTable, { TableColumn } from 'react-data-table-component';
import { useState, useEffect, useMemo, Ref } from 'react';
import Export from '../CreateExportData';
import { Report } from '../../interface/Report';
import RowButtonsComponent from './RowButtonsComponent';
import '../../../common.css';

interface TableProps {
  reports: Report[];
  selectRow: ({ selectedRows }: { selectedRows: any }) => void;
  isClearSelectedRow: boolean;
  moveConfirmPage: (record: any) => void;
  moveEditPage: (record: any) => void;
  isDisplayEdit: boolean;
  hiddenDLButtonRef: Ref<HTMLButtonElement>;
  selectableRowsVisibleOnly: boolean;
}
const Table: React.FC<TableProps> = ({
  reports,
  selectRow,
  isClearSelectedRow,
  moveConfirmPage,
  moveEditPage,
  isDisplayEdit,
  hiddenDLButtonRef,
  selectableRowsVisibleOnly,
}) => {
  const getDisplayText = (text: string) => {
    if (text === null) return '';
    if (text.length > 15) return `${text.slice(0, 15)}...`;
    return text;
  };

  const baseColumns: Array<TableColumn<any>> = [
    {
      name: '授業情報',
      reorder: false,
      maxWidth: '10rem',
      sortable: true,
      selector: (row) => row.classInfo.subject + ' ' + String(row.classInfo.grade) + '学年' + ' ' + row.classInfo.personCount + '人',
      format: (row) => getDisplayText(row.classInfo.subject + ' ' + String(row.classInfo.grade) + '学年' + ' ' + row.classInfo.personCount + '人'),
      wrap: true,
    },
    {
      name: '入手・掲載元種別',
      reorder: false,
      minWidth: '130px',
      selector: (row) => row.media.categories[0],
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(row.media.categories[0]),
    },
    {
      name: '入手・掲載元の名称',
      reorder: false,
      minWidth: '130px',
      sortable: true,
      wrap: true,
      format: (row) => getDisplayText(row.media.info.textbook_name || row.media.info.site_title || row.media.info.exam_title || row.media.info.title),
      selector: (row) => row.media.info.textbook_name || row.media.info.site_title || row.media.info.exam_title || row.media.info.title,
    },
    {
      name: '著作物の分類',
      reorder: false,
      maxWidth: '12rem',
      selector: (row) => row.work.subCategory.slice(2),
      format: (row) => getDisplayText(row.work.subCategory.slice(2)),
      sortable: true,
      wrap: true,
    },
    {
      name: '差戻理由',
      reorder: false,
      maxWidth: '12rem',
      sortable: true,
      wrap: true,
      selector: (row) => (row.comment ? row.comment.content : ''),
      format: (row) => getDisplayText(row.comment ? row.comment.content : ''),
    },
    {
      name: '',
      sortable: false,
      reorder: false,
      minWidth: '150px',
      cell: (record) => {
        return (
          <RowButtonsComponent
            isDisplayConfirmButton={true}
            isDisplayEditButton={isDisplayEdit}
            isDisplayCopyButton={false}
            handleClickConfirmButton={() => moveConfirmPage(record)}
            handleClickEditButton={() => moveEditPage(record)}
          />
        );
      },
    },
    {
      name: 'シリアルコード',
      reorder: false,
      minWidth: '180px',
      selector: (row) => row._id,
      style: { color: 'gray' },
      sortable: true,
      wrap: true,
    },
  ];
  const [columns, setColumns] = useState<Array<TableColumn<any>>>(baseColumns);
  const downloadXlsxData = useMemo(() => <Export data={reports} hiddenDLButtonRef={hiddenDLButtonRef} />, [reports]);

  useEffect(() => {
    setColumns(baseColumns);
  }, [isDisplayEdit]);

  return (
    <>
      <DataTable
        className="mt-1"
        columns={columns}
        data={reports}
        selectableRows={true}
        onSelectedRowsChange={selectRow}
        pagination
        paginationResetDefaultPage={false}
        clearSelectedRows={isClearSelectedRow}
        noDataComponent="条件に一致する利用報告はありません。"
        responsive={true}
        selectableRowsVisibleOnly={selectableRowsVisibleOnly}
        actions={downloadXlsxData}
        noContextMenu
      />
    </>
  );
};
export default Table;
