import { ArrowLeft } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const BackButton = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Button variant="primary" type="button" className="m-10" onClick={() => navigate(-1)}>
      <ArrowLeft />
      戻る
    </Button>
  );
};

export default BackButton;
