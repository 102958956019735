import api from '../api/CallUserApi';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Prop {
  error?: any;
  resetError?: () => void;
}

const Login: React.FunctionComponent<Prop> = ({ error, resetError }) => {
  const navigate = useNavigate();

  const clickNext = () => {
    const getSession = async () => {
      try {
        await api.getSession();
        navigate('/users/select');
      } catch (e: any) {
        navigate('/users/login');
      } finally {
        if (resetError) {
          resetError();
        }
      }
    };
    getSession();
  };

  const moveReportsPage = () => {
    if (resetError) {
      resetError();
    }
    navigate('/users/reports');
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <Card className="card-center mt-20">
              <Card.Body>
                ページでエラーが発生しました。
                {error && <div>{error.statusText}</div>}
                {error && <div>{error.status}</div>}
                {error && <div>{error.message}</div>}
              </Card.Body>
              <Card.Footer className="ta-right">
                {error && (error.status === 401 || error.status === 500) && (
                  <Button variant="primary" type="button" onClick={() => clickNext()}>
                    ログインページへ
                  </Button>
                )}
                {error && error.status !== 401 && error.status !== 500 && (
                  <Button variant="primary" type="button" onClick={() => moveReportsPage()}>
                    利用報告一覧へ
                  </Button>
                )}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
