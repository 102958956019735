import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { FrontWork } from '../interface/FrontWork';
import { Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api/CallManagerApi';
import { getNextUrl, moveReportsPage, getInputListByPrevData } from '../utils';
import InputWork from '../components/InputWork';
import {
  getWorksTmpById,
  getRequiredMediaCategoryTmpById,
  isHalfWritten,
  getRequiredHalfWrittenMediaCategoryById,
  getHalfWrittenWorksById,
} from '../localStorageUtils';
import { useErrorBoundary } from 'react-error-boundary';

const RegisterWork: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const [works, setWorks] = useState<FrontWork[]>([]);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const prevWorks = getWorksTmpById(profile._id);
      if (prevWorks) {
        setWorks(getInputListByPrevData(prevWorks));
        return;
      }

      try {
        const categories = getRequiredMediaCategoryTmpById(profile._id);
        if (isHalfWritten(profile._id)) {
          const oldMediaCategory = getRequiredHalfWrittenMediaCategoryById(profile._id);
          if (categories === oldMediaCategory) {
            // 一時保存復帰
            const halfWittenWorks = getHalfWrittenWorksById(profile._id);
            if (halfWittenWorks) {
              setWorks(getInputListByPrevData(halfWittenWorks));
              return;
            }
          }
        }
      } catch (e) {
        showBoundary(e);
      }

      setWorks([{ data: {}, disabled: true }]);
    };
    getData();
  }, []);

  const moveNextUrl = () => {
    const url = getNextUrl(location.pathname);
    navigate(url);
  };
  const reportsPage = () => {
    const url = moveReportsPage(location.pathname);
    navigate(url);
  };

  return (
    <>
      <Container>
        <InputWork
          userInfo={userInfo}
          moveNextUrl={moveNextUrl}
          moveReportsPage={reportsPage}
          worksByUpperLayer={works}
          isDisplayAddDeleteButton={true}
          isDisplayHalfWrittenButton={true}
        />
      </Container>
    </>
  );
};
export default RegisterWork;
