import { Button, Modal } from 'react-bootstrap';

interface Props {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  message: string;
  nextStep: () => void;
}

const ConfirmYesNoModal: React.FC<Props> = ({ show, setShow, message, nextStep }) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          いいえ
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            nextStep();
            setShow(false);
          }}
        >
          はい
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmYesNoModal;
