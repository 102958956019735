import React, { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { ClassInfo } from '../interface/ClassInfo';
import { Container } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api/CallUserApi';
import { getNextUrl } from '../utils';
import { getLessonTmpById, isHalfWritten, getRequiredHalfWrittenLessonById } from '../localStorageUtils';
import InputLesson from '../components/InputLesson';
import { useErrorBoundary } from 'react-error-boundary';

const RegisterLesson: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [formData, setFormData] = useState<ClassInfo>({} as ClassInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();

  const nextPage = (): void => {
    const nextPage = getNextUrl(location.pathname);
    navigate(nextPage);
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);

      const lesson: ClassInfo | null = getLessonTmpById(profile._id);
      if (lesson) {
        setFormData(lesson);
        return;
      }

      if (isHalfWritten(profile._id)) {
        // 途中復帰の場合
        try {
          setFormData(getRequiredHalfWrittenLessonById(profile._id));
        } catch (error) {
          showBoundary(error);
        }
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <InputLesson userInfo={userInfo} movePage={nextPage} formData={formData} />
      </Container>
    </>
  );
};
export default RegisterLesson;
