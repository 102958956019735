// src/store/loading/loadingReducer.ts
import { createSlice } from '@reduxjs/toolkit';

const institutionSlice = createSlice({
  name: 'institution',
  initialState: {
    responsibleTerm: {
      year: 9999,
      month: 99,
    },
    name: '',
  },
  reducers: {
    setGlobalInstitution: (state, action) => {
      state.responsibleTerm = action.payload.responsibleTerm;
      state.name = action.payload.name;
    },
  },
});

export const { setGlobalInstitution } = institutionSlice.actions;

export default institutionSlice.reducer;
