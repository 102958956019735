import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container, Col, Row } from 'react-bootstrap';
import api from '../api/CallOwnerApi';
import { useParams } from 'react-router-dom';
import InputMedia from '../components/InputMedia';
import React from 'react';
import HelpArea from '../components/HelpComponent';
import { getMediaTmpById } from '../localStorageUtils';

const RegisterMedia: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [formData, setFormData] = useState<any>({});
  const navigate = useNavigate();
  const params = useParams();
  const [reportId, setReportId] = useState<string>('');
  const [helps, setHelps] = useState<any[]>([]);
  const [isFinishedGettingData, setIsFinishedGettingData] = useState<boolean>(false);

  const reportsPage = () => {
    navigate('/owner/reports');
  };

  const moveWorkPage = () => {
    navigate('/owner/report/copy/' + reportId + '/work');
  };

  const getFormData = (userId: string) => {
    const getData = async () => {
      const id = params ? params.reportId : '';
      if (!id) throw Error('report id パラメータが存在しません');
      setReportId(id);
      const prevMedia = getMediaTmpById(userId);
      if (prevMedia) {
        setFormData(prevMedia);
        setIsFinishedGettingData(!isFinishedGettingData);
        return;
      }
      setIsFinishedGettingData(!isFinishedGettingData);
    };
    getData();
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      getFormData(profile._id);
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <InputMedia
              userInfo={userInfo}
              moveReportsPage={reportsPage}
              moveWorkPage={moveWorkPage}
              isDisplayHalfWrittenButton={true}
              inputFormData={formData}
              isFinishedGettingData={isFinishedGettingData}
              setDisplayHelpData={setHelps}
            />
          </Col>
          <HelpArea helps={helps} />
        </Row>
      </Container>
    </>
  );
};
export default RegisterMedia;
