import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { useState } from 'react';
import api from '../api/CallUserApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { useNavigate } from 'react-router-dom';
import BackButton from '../components/BackButton';
import { useErrorBoundary } from 'react-error-boundary';

const CreateUser: React.FunctionComponent = () => {
  const [name, setName] = useState<string>('');
  const [needPassword, setNeedPassword] = useState<boolean>(false);
  const [isDisplayName, setIsDisplayName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isDisabledPass, setIsDisabledPass] = useState<boolean>(true);
  const navigate = useNavigate();
  const { showBoundary } = useErrorBoundary();

  const checkNeedPass = () => {
    if (needPassword) {
      setIsDisabledPass(true);
      setPassword('');
    } else {
      setIsDisabledPass(false);
    }
    setNeedPassword(!needPassword);
  };

  const createUser = () => {
    const callUserApi = async () => {
      const passwordFlag = password ? true : false;
      try {
        await api.createUser(name, isDisplayName, passwordFlag, password);
        navigate('/users/select');
        toast.success('新規ユーザーの登録が完了しました', toastConfig());
      } catch (e: any) {
        switch (e.response.status) {
          case 400:
            toast.error('ユーザーの作成に失敗しました。', toastConfig());
            break;
          case 409:
            toast.error('既に存在する名前です。', toastConfig());
            break;
          default:
            showBoundary(e.response);
        }
      }
    };
    callUserApi();
  };

  return (
    <div>
      <Container>
        <Row>
          <Col md={{ span: 6, offset: 3 }}>
            <div className="ta-left">
              <BackButton />
            </div>
            <Card className="card-center">
              <div className="ta-center mt-20">
                <PersonCircle color="royalblue" size={100} />
              </div>
              <Card.Body>
                <Card.Title>新規ユーザー作成</Card.Title>
                ユーザー名
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                  <Form.Text className="text-muted">教育機関担当者のみ確認できる名前です。</Form.Text>
                </Form.Group>
                表示名
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={isDisplayName}
                    onChange={(event) => {
                      setIsDisplayName(event.target.value);
                    }}
                  />
                  <Form.Text className="text-muted">ログイン後のユーザー一覧画面に表示される名前です。</Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="パスワード設定"
                    checked={needPassword}
                    onChange={() => {
                      checkNeedPass();
                    }}
                  />
                </Form.Group>
                パスワード
                <Form.Group>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(event) => {
                      setPassword(event.target.value);
                    }}
                    disabled={isDisabledPass}
                  />
                </Form.Group>
              </Card.Body>
              <Card.Footer className="ta-right">
                <Button
                  variant="primary"
                  as="input"
                  type="button"
                  value="新規作成"
                  className="m-10"
                  onClick={() => {
                    createUser();
                  }}
                />
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default CreateUser;
