import { Button, Modal, Form } from 'react-bootstrap';
import { useState } from 'react';
import { Institution } from '../../interface/Institution';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  institutions: Institution[];
  changeInstitution: (value: string) => void;
}

const SelectModal: React.FC<ModalProps> = ({ show, setShow, institutions, changeInstitution }) => {
  const [validation, setValidation] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');

  const changeItem = (value: string) => {
    setSelected(value);
  };

  const send = () => {
    if (!selected) {
      setValidation(true);
      return;
    }
    setValidation(false);
    changeInstitution(selected);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>教育機関選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>
          教育機関 <span className="badge badge-danger">必須</span>
        </Form.Label>
        <Form.Control
          as="select"
          isInvalid={validation}
          onChange={(event) => {
            changeItem(event.target.value);
          }}
        >
          <option value=""></option>
          {institutions.map((item, index) => (
            <option key={index} value={item._id}>
              {item.name}
            </option>
          ))}
        </Form.Control>
        <Form.Control.Feedback type="invalid">教育機関を選択してください。</Form.Control.Feedback>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            send();
          }}
        >
          選択
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectModal;
