import { useState, useEffect } from 'react';
import { Button, Row, Col, Container } from 'react-bootstrap';
import Stepper from './Stepper';
import SelectSmallMediaCategory from './SelectSmallMediaCategory';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import BackButton from './BackButton';
import { MediaCategory } from '../interface/MediaCategory';
import { setMediaCategoryTmp, removeMediaTmpById } from '../localStorageUtils';

interface Props {
  bigMediaCategory: string;
  movePage: () => void;
  mediaCategory: MediaCategory[];
  selectedMediaCategoryByUpperLayer: string;
  userInfo: BaseUserInfo;
  setDisplayHelpData: (data: any) => void;
}

const Category: React.FC<Props> = ({
  bigMediaCategory,
  movePage,
  mediaCategory,
  selectedMediaCategoryByUpperLayer,
  userInfo,
  setDisplayHelpData,
}) => {
  const [isDisabledNextButton, setIsDisabledNextButton] = useState(true);
  const [selectedMediaCategory, setSelectedMediaCategory] = useState<string>('');
  const [isGettingHelp, setIsGettingHelp] = useState(false);

  const nextPage = () => {
    setMediaCategoryTmp(userInfo._id, selectedMediaCategory);
    removeMediaTmpById(userInfo._id);
    movePage();
  };

  const clearSelect = () => {
    setIsDisabledNextButton(true);
    setSelectedMediaCategory('');
    setIsGettingHelp(!isGettingHelp);
  };

  useEffect(() => {
    setSelectedMediaCategory(selectedMediaCategoryByUpperLayer);
  }, [selectedMediaCategoryByUpperLayer]);

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 12 }}>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }}>
            <Stepper activeNum={1} />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 12 }}>
            <h4>STEP2 {bigMediaCategory + 'のジャンルを選択してください'}</h4>
            <hr className="border-1-solid" />
            <div>
              <SelectSmallMediaCategory
                mediaData={mediaCategory}
                selectedMediaCategory={selectedMediaCategory}
                setButtonDisabled={setIsDisabledNextButton}
                setSelectedMediaCategory={setSelectedMediaCategory}
                bigMediaCategory={bigMediaCategory}
                setDisplayHelpData={setDisplayHelpData}
                isGettingHelp={isGettingHelp}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="ta-right">
              <Button
                variant="danger"
                as="input"
                type="button"
                value="選択クリア"
                className="m-10"
                onClick={() => {
                  clearSelect();
                }}
              />
              <Button
                disabled={isDisabledNextButton}
                variant="success"
                as="input"
                type="button"
                value="次へ"
                className="m-10"
                onClick={() => {
                  nextPage();
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Category;
