import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Container, Button, Card } from 'react-bootstrap';
import { TableColumn } from 'react-data-table-component';
import api from '../api/CallOwnerApi';
import BackButton from '../components/BackButton';
import ConfirmSubmissionModal from '../components/modal/ConfirmSubmissionModal';
import GridTable from '../components/GridTable';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { OwnerInstitutionsPageData } from '../interface/OwnerInstitutionsPageData';

const ShowInstitutionsPage: React.FunctionComponent = () => {
  const [institutions, setInstitutions] = useState<OwnerInstitutionsPageData[]>([]);
  const [selectedInstitutionId, setSelectedInstitutionId] = useState<any>();
  const [resetPagination, setResetPagination] = useState<any>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const columns: Array<TableColumn<any>> = [
    {
      name: '報告対象期間',
      sortable: true,
      maxWidth: '150px',
      selector: (row) => (row.responsibleTerm?.year ?? ' - ') + '年' + (row.responsibleTerm?.month ?? ' - ') + '月',
    },
    {
      name: '教育機関名',
      sortable: true,
      selector: (row) => row.name,
    },
    {
      name: '承認済の報告数',
      sortable: true,
      maxWidth: '150px',
      selector: (row) => (row.isSubmission ? row.reportNumber + '件' : '利用なし'),
    },
    {
      name: '',
      sortable: false,
      reorder: false,
      maxWidth: '320px',
      cell: (row) => {
        return (
          <div>
            <Button variant="primary" className="m-1 font-small" type="button" onClick={() => showInstitutionManagementPage(row.tableIndex)}>
              教育機関担当者
            </Button>
            <Button
              variant="danger"
              className="m-1 font-small"
              type="button"
              onClick={() => clickSubmission(row.institutionId)}
              disabled={row.reportNumber !== 0 || !row.isSubmission}
            >
              報告対象の利用なし
            </Button>
          </div>
        );
      },
    },
  ];

  const displayInstitutions = () => {
    (async () => {
      setResetPagination(true);
      dispatch(startLoading());
      setInstitutions(await api.getAdditionalReportNumberInstitutions());
      setResetPagination(false);
      dispatch(stopLoading());
    })();
  };

  const clickSubmission = async (institutionId: string) => {
    setSelectedInstitutionId(institutionId);
    setShowModal(true);
  };

  const showInstitutionManagementPage = (selectId: number) => {
    navigate('managerInfo', { state: { selectId: selectId, institutions: institutions } });
  };

  useEffect(() => {
    displayInstitutions();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="m-10" style={{ fontSize: '14px', backgroundColor: '#E3F1FF' }}>
              <Card.Body>
                <p>この画面では以下の操作を行えます。</p>
                <p>
                  <b>
                    1. 教育機関担当者の登録・変更　
                    <a style={{ color: 'red' }}>2024年度の担当者は、2024年3月27日（水）までにご登録ください</a>
                  </b>
                </p>
                <div>
                  ご登録いただいた教育機関の担当者様へ、各教育機関でお使いいただくTSUMUGIアカウント（教育機関担当者用アカウント、入力者用アカウント）をお送りします。
                  <br />
                  設置者の担当者様が教育機関の担当を兼ねていただく場合は、設置者の担当者様の情報を教育機関担当者としてもご登録ください。
                  <br />
                </div>
                <br />
                <div>
                  担当者に変更が生じた際は、随時、登録内容を修正してください。
                  <br />
                  ※教育機関担当者のTSUMUGIアカウントからも担当者の修正は可能です。
                  <br />
                </div>
                <br />
                <p>
                  <b>2. 報告対象の利用がない場合</b>
                </p>
                <div>
                  対象期間において著作物の授業目的公衆送信がなかった場合（教育機関からの提出がなかった場合）は、「報告対象の利用なし」を押してください。
                  <br />
                  （別途のご連絡はご不要となります。）
                  <br />
                </div>
                <br />
                <div>
                  ※「報告対象の利用なし」のボタンは、このアカウント（設置者の皆様にお送りしているアカウント）にのみ設けておりますのでご留意ください。
                  <br />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <GridTable columns={columns} data={institutions} fontSize="16px" resetPagination={resetPagination} />
          </Col>
        </Row>
      </Container>
      <ConfirmSubmissionModal
        show={showModal}
        setShow={setShowModal}
        institutionId={selectedInstitutionId}
        updateDisplayFunc={displayInstitutions}
        markAsNoSubmissionAPI={api.markAsNoSubmission}
      />
    </>
  );
};
export default ShowInstitutionsPage;
