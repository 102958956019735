import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import api from '../api/CallCreateReportApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { getLoginUrl } from '../utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';

function ImageWidget(props: WidgetProps) {
  const [imageUri, setImageUri] = useState<string>(props.value);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [disabledUploadButton, setDisabledUploadButton] = useState<boolean>(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const getUri = () => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        if (selectedFile) {
          const data = await api.getImageUri(selectedFile);
          setImageUri(decodeURI(data));
        }
      } catch (e: any) {
        if (e.response.status === 401) {
          const url = getLoginUrl(location.pathname);
          navigate(url);
          setErrorMsg('');
        } else if (e.response.status === 400) {
          setErrorMsg('アップロードできるのは画像のみです。');
        } else {
          setErrorMsg('エラーが発生しました。');
        }
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  };

  const setFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size > 5242880) {
        setErrorMsg('画像ファイルが大きすぎます。画像ファイルは5MB以内に設定してください。');
        setDisabledUploadButton(true);
        return;
      }
      setSelectedFile(file);
      setErrorMsg('');
      setDisabledUploadButton(false);
    }
  };

  useEffect(() => {
    props.onChange(imageUri);
  }, [imageUri]);

  return (
    <div>
      <Form.Row>
        <Col>
          <Form.File
            onChange={(event) => {
              setFile(event);
            }}
          />
          <Form.Text className="text-muted">
            ①以下の条件に合致する画像ファイルを選択してください。
            <br />
            拡張子: jpeg、jpg、png、gif
            <br />
            サイズ: 5MBまで
          </Form.Text>
        </Col>
        <Col>
          <Button
            variant="primary"
            type="button"
            as="input"
            value="アップロード"
            className="m-10"
            onClick={() => {
              getUri();
            }}
            disabled={disabledUploadButton}
          />
          <Form.Text className="text-muted">②「アップロード」ボタンを押下すると、下の「画像URL」欄にURLが自動設定されます。</Form.Text>
        </Col>
      </Form.Row>
      <div className="d-block invalid-feedback">{errorMsg}</div>
      <div className="ta-center m-10">
        <ArrowDownCircleFill color="royalblue" size={30} />
      </div>
      <Form.Row>
        <Col>
          <label htmlFor="imageUri_id">画像URL</label>
          <Form.Control type="text" id="imageUri_id" readOnly={true} value={imageUri} />
          <Form.Text className="text-muted">③「アップロード」ボタンを押下すると、自動入力されます。</Form.Text>
        </Col>
      </Form.Row>
    </div>
  );
}

export default ImageWidget;
