import { CSSProperties } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

const Loading = () => {
  return (
    <div className="overlay">
      <BeatLoader loading={true} color={'#007bff'} cssOverride={override} size={50} aria-label="Loading Spinner" data-testid="loader" />
    </div>
  );
};

export default Loading;
