import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container, Col, Row } from 'react-bootstrap';
import api from '../api/CallOwnerApi';
import { getNextUrl, moveReportsPage } from '../utils';
import InputMedia from '../components/InputMedia';
import HelpArea from '../components/HelpComponent';
import {
  getMediaTmpById,
  getRequiredMediaCategoryTmpById,
  isHalfWritten,
  getRequiredHalfWrittenMediaCategoryById,
  getRequiredHalfWrittenMediaById,
} from '../localStorageUtils';
import { useErrorBoundary } from 'react-error-boundary';

const RegisterMedia: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const [formData, setFormData] = useState<any>({});
  const [helps, setHelps] = useState<any[]>([]);
  const [isFinishedGettingData, setIsFinishedGettingData] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();

  const reportsPage = () => {
    const nextPage = moveReportsPage(location.pathname);
    navigate(nextPage);
  };

  const moveWorkPage = () => {
    const nextPage = getNextUrl(location.pathname);
    navigate(nextPage);
  };

  const getFormData = (userId: string) => {
    const prevMedia = getMediaTmpById(userId);
    if (prevMedia) {
      setFormData(prevMedia);
      setIsFinishedGettingData(!isFinishedGettingData);
      return;
    }
    try {
      const categories = getRequiredMediaCategoryTmpById(userId);
      if (isHalfWritten(userId)) {
        const oldMediaCategory = getRequiredHalfWrittenMediaCategoryById(userId);
        if (categories === oldMediaCategory) {
          // 途中復帰
          const media = getRequiredHalfWrittenMediaById(userId);
          setFormData(media);
        }
      }
    } catch (e) {
      showBoundary(e);
    }
    setIsFinishedGettingData(!isFinishedGettingData);
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      getFormData(profile._id);
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <InputMedia
              userInfo={userInfo}
              moveReportsPage={reportsPage}
              moveWorkPage={moveWorkPage}
              isDisplayHalfWrittenButton={true}
              inputFormData={formData}
              isFinishedGettingData={isFinishedGettingData}
              setDisplayHelpData={setHelps}
            />
          </Col>
          <HelpArea helps={helps} />
        </Row>
      </Container>
    </>
  );
};
export default RegisterMedia;
