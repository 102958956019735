import { Button } from 'react-bootstrap';
import { BoxArrowUpRight } from 'react-bootstrap-icons';

interface RowButtonsComponentProps {
  isDisplayConfirmButton: boolean;
  isDisplayEditButton: boolean;
  isDisplayCopyButton: boolean;
  handleClickConfirmButton: () => void;
  handleClickEditButton: () => void;
  handleClickCopyButton?: () => void;
}

const RowButtonsComponent: React.FC<RowButtonsComponentProps> = ({
  isDisplayConfirmButton,
  isDisplayEditButton,
  isDisplayCopyButton,
  handleClickConfirmButton,
  handleClickEditButton,
  handleClickCopyButton,
}) => {
  return (
    <div>
      {isDisplayConfirmButton && (
        <Button variant="primary" className="m-1 font-small" type="button" size="sm" onClick={handleClickConfirmButton} style={{ width: '65px' }}>
          確認
          <BoxArrowUpRight size={12} className="document-icon" />
        </Button>
      )}
      {isDisplayEditButton && (
        <Button variant="primary" className="m-1 font-small" type="button" size="sm" onClick={handleClickEditButton} style={{ width: '60px' }}>
          編集
        </Button>
      )}
      {isDisplayCopyButton && (
        <Button variant="primary" className="m-1 font-small" type="button" size="sm" onClick={handleClickCopyButton} style={{ width: '135px' }}>
          コピー元として利用
        </Button>
      )}
    </div>
  );
};

export default RowButtonsComponent;
