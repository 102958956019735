import { WidgetProps } from '@rjsf/utils';
import { Button, Form, Col, Dropdown, Alert } from 'react-bootstrap';
import { useState } from 'react';
import api from '../api/CallListApi';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import Modal from './modal/SelectNHKForSchoolModal';
import Axios from 'axios';
import { useErrorBoundary } from 'react-error-boundary';

const subjectApiCodeDict = {
  すべて: '0',
  国語: '1',
  社会: '2',
  地理歴史: '3',
  公民: '4',
  '算数/数学': '5',
  理科: '6',
  生活: '7',
  音楽: '8',
  図工: '9',
  道徳: 'K',
  体育: 'G',
  技術: 'C',
  家庭: 'F',
  総合: 'M',
  '英語（外国語・外国語活動）': 'H',
  特活: 'N',
  '特支（自立活動）': 'O',
  '幼保（ねらい及び内容）': 'l',
  芸術: 'B',
  情報: 'I',
  商業: 'R',
};
const gradeApiCodeDict = {
  すべて: '0',
  幼保: '10',
  小1: '21',
  小2: '22',
  小3: '23',
  小4: '24',
  小5: '25',
  小6: '26',
  中学: '30',
  高校: '40',
  特支: '60',
};

function NHKForSchoolWidget(props: WidgetProps) {
  const [title, setTitle] = useState<string>('');
  const [subTitle, setSubTitle] = useState<string>('');
  const [subject, setSubject] = useState<string>('すべて');
  const [grade, setGrade] = useState<string>('すべて');
  const [program, setProgram] = useState<boolean>(false);
  const [clip, setClip] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [itemList, setItemList] = useState<any[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { showBoundary } = useErrorBoundary();
  const dispatch = useDispatch();

  const search = () => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        const category: '0' | '1' | '2' = (() => {
          if (program && clip) return '0';
          else if (program) return '1';
          else if (clip) return '2';
          return '0';
        })();
        const data = await api.getNHKForSchool(title, subTitle, subjectApiCodeDict[subject], gradeApiCodeDict[grade], category);
        setErrorMsg('');
        setShowAlert(false);
        setItemList(data);
        setShowModal(true);
      } catch (e: any) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
        if (e.response.status === 400) {
          setErrorMsg(e.response.data);
          setShowAlert(true);
        } else showBoundary(e);
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  };

  const setSelectedItem = (item: any) => {
    setShowModal(false);
    props.formContext.updateNHKForSchool(item);
  };

  const subjectDropdown = () => {
    const items = Object.keys(subjectApiCodeDict).map((subject) => (
      <Button
        variant="secondary"
        as="input"
        type="button"
        value={subject}
        className="m-10"
        onClick={() => {
          setSubject(subject);
        }}
      />
    ));
    return <div>{items}</div>;
  };

  const gradeDropdown = () => {
    const items = Object.keys(gradeApiCodeDict).map((grade) => (
      <Button
        variant="secondary"
        as="input"
        type="button"
        value={grade}
        className="m-10"
        onClick={() => {
          setGrade(grade);
        }}
      />
    ));
    return <div>{items}</div>;
  };

  return (
    <div>
      <Form.Row>
        <Col md={12}>
          <Alert variant="danger" show={showAlert}>
            {errorMsg}
          </Alert>
        </Col>
        <Col md={12} lg={2}>
          <label htmlFor="title_id">タイトル</label>
          <Form.Control
            type="text"
            id="title_id"
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </Col>
        <Col md={12} lg={2}>
          <label htmlFor="subTitle_id">サブタイトル</label>
          <Form.Control
            type="text"
            id="subTitle_id"
            onChange={(event) => {
              setSubTitle(event.target.value);
            }}
          />
        </Col>
        <Col>
          <label htmlFor="subject_id">教科</label>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="w-100">
              {subject && subject}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item as={subjectDropdown} />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <label htmlFor="grade_id">学年</label>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic" className="w-100">
              {grade && grade}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <Dropdown.Item as={gradeDropdown} />
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <label htmlFor="textNumber_id">動画の種類</label>
          <Form.Check
            inline
            as="input"
            label="ばんぐみ"
            name="ばんぐみ"
            type="checkbox"
            id="program_id"
            checked={program}
            onChange={(event) => {
              setProgram(event.target.checked);
            }}
          />
          <Form.Check
            inline
            as="input"
            label="クリップ"
            name="クリップ"
            type="checkbox"
            id="clip_id"
            onChange={(event) => {
              setClip(event.target.checked);
            }}
          />
        </Col>
        <Col md="auto">
          <Button
            variant="primary"
            type="button"
            as="input"
            value="検索"
            className="m-10"
            onClick={() => {
              search();
            }}
          />
        </Col>
      </Form.Row>
      <Modal show={showModal} setShow={setShowModal} itemList={itemList} nextStep={setSelectedItem} />
    </div>
  );
}

export default NHKForSchoolWidget;
