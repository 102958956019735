import { FallbackProps } from 'react-error-boundary';
import UserErrorPage from '../users/ErrorPage';
import ManagerErrorPage from '../manager/ErrorPage';
import OwnerErrorPage from '../owner/ErrorPage';
import AdminErrorPage from '../admin/ErrorPage';
import { useLocation } from 'react-router-dom';

const ErrorFallback: React.FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const location = useLocation();
  return (
    <div>
      {location.pathname.includes('/users/') && <UserErrorPage error={error} resetError={resetErrorBoundary} />}
      {location.pathname.includes('/manager/') && <ManagerErrorPage error={error} resetError={resetErrorBoundary} />}
      {location.pathname.includes('/owner/') && <OwnerErrorPage error={error} resetError={resetErrorBoundary} />}
      {location.pathname.includes('/admin/') && <AdminErrorPage error={error} resetError={resetErrorBoundary} />}
    </div>
  );
};
export default ErrorFallback;
