import React, { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallManagerApi';
import { useParams } from 'react-router-dom';
import InputLesson from '../components/InputLesson';
import { getLessonTmpById } from '../localStorageUtils';
import { ClassInfo } from '../interface/ClassInfo';

const RegisterLesson: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [formData, setFormData] = useState<ClassInfo>({} as ClassInfo);
  const [reportId, setReportId] = useState<string>('');
  const navigate = useNavigate();
  const params = useParams();

  const nextPage = (): void => {
    navigate('/manager/report/edit/' + reportId + '/bigMediaCategory');
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const id = params ? params.reportId : '';
      if (!id) throw Error('report id パラメータが存在しません');
      setReportId(id);
      const lesson = getLessonTmpById(profile._id);
      if (lesson) {
        setFormData(lesson);
        return;
      }
      const report = await api.getReport(id);
      setFormData(report.classInfo);
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <InputLesson userInfo={userInfo} movePage={nextPage} formData={formData} />
      </Container>
    </>
  );
};
export default RegisterLesson;
