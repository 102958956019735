import { Overlay, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEffect, useState } from 'react';

interface Props {
  target: any;
}

const NoReportTooltip: React.FC<Props> = ({ target }) => {
  const displayNoReportTooltip = useSelector((state: RootState) => state.noReportTooltip.display);
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const setTimer = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setShow(true);
    };
    displayNoReportTooltip ? setTimer() : setShow(false);
  }, [displayNoReportTooltip]);

  return (
    <Overlay target={target.current} show={show} placement="bottom">
      {(props) => (
        <Tooltip id="overlay-example" {...props}>
          利用報告の作成はこちらから行えます。
        </Tooltip>
      )}
    </Overlay>
  );
};

export default NoReportTooltip;
