import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { ArrowDownCircleFill } from 'react-bootstrap-icons';
import { getSmallMediaCategoryHelpComponent } from '../helpUtils';
import { MediaCategory } from '../interface/MediaCategory';

interface Props {
  setButtonDisabled: (value: boolean) => void;
  setSelectedMediaCategory: (value: string) => void;
  mediaData: MediaCategory[];
  selectedMediaCategory: string;
  setDisplayHelpData: (data: any) => void;
  bigMediaCategory: string;
  isGettingHelp: boolean;
}

interface ButtonsProps {
  setButtonDisabled: (value: boolean) => void;
  setSelectedMediaCategory: (value: string) => void;
  mediaData: MediaCategory[];
  selectedItem: string;
  itemNum: number;
  setDisplayHelpData: (data: any) => void;
  bigMediaCategory: string;
  isGettingHelp: boolean;
}

const Buttons: React.FC<ButtonsProps> = ({
  setButtonDisabled,
  setSelectedMediaCategory,
  mediaData,
  selectedItem,
  itemNum,
  setDisplayHelpData,
  bigMediaCategory,
  isGettingHelp,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<MediaCategory>({} as MediaCategory);

  useEffect(() => {
    if (!mediaData || mediaData.length === 0) return;
    setSelectedValue(selectedItem);
    setSelectedMediaCategory(selectedItem);
    const foundItem = getSelectedValue(selectedItem, mediaData);
    setSelectedOption(!foundItem ? {} : foundItem);
    if (foundItem && foundItem.children.length === 0) setButtonDisabled(false);
  }, [mediaData, selectedItem]);

  useEffect(() => {
    if (!mediaData || mediaData.length === 0) return;
    getHelp();
  }, [mediaData]);

  useEffect(() => {
    getHelp();
  }, [isGettingHelp]);

  const getHelp = () => {
    Promise.allSettled(mediaData.map((item) => getSmallMediaCategoryHelpComponent(bigMediaCategory, item.label)))
      .then((results: PromiseSettledResult<any>[]) => {
        const success: any[] = results.filter((result) => result.status === 'fulfilled');
        const Components = success.map((item) => item.value);
        setDisplayHelpData(Components);
      })
      .catch(() => {
        setDisplayHelpData([]);
      });
  };

  const selectData = (value: string): void => {
    setSelectedValue(value);
    setSelectedMediaCategory(value);
    const foundItem = getSelectedValue(value, mediaData);
    setSelectedOption(foundItem);
    if (value !== '' && foundItem?.children && foundItem?.children.length === 0) {
      // select last element
      setButtonDisabled(false);
      setDisplayHelpData([]);
      return;
    }
    setButtonDisabled(true);
  };

  const isActive = (values: string[], selectedItems: string) => {
    const selectedItemArray = selectedItems.split(',');
    for (let i = 0; i < itemNum; i++) {
      if (selectedItemArray[i] === values[i]) {
        continue;
      } else {
        return false;
      }
    }
    return true;
  };

  const getSelectedValue = (selectedItem: string, array: any[]) => {
    const selectedItemArray = selectedItem.split(',');
    for (const item of array) {
      const itemArray = item.value;
      let result = true;
      for (let i = 0; i < itemNum; i++) {
        if (selectedItemArray[i] === itemArray[i]) {
          continue;
        } else {
          result = false;
          break;
        }
      }
      if (result) {
        return item;
      }
    }
    return undefined;
  };

  return (
    <div className="ta-center">
      {mediaData.map((item) => (
        <Button
          key={item.key}
          value={item.value}
          className="highlighted-button m-10 smallCategory-button"
          active={isActive(item.value, selectedValue)}
          onClick={() => {
            selectData(String(item.value));
          }}
        >
          {item.label}
        </Button>
      ))}
      {selectedOption?.children && selectedOption?.children.length !== 0 && (
        <div className="ta-center m-10">
          <ArrowDownCircleFill color="royalblue" size={30} />
        </div>
      )}
      {selectedOption?.children && selectedOption?.children.length !== 0 && (
        <Buttons
          setButtonDisabled={setButtonDisabled}
          setDisplayHelpData={setDisplayHelpData}
          bigMediaCategory={bigMediaCategory}
          mediaData={selectedOption?.children}
          setSelectedMediaCategory={setSelectedMediaCategory}
          selectedItem={selectedValue}
          itemNum={itemNum + 1}
          isGettingHelp={isGettingHelp}
        />
      )}
    </div>
  );
};

const App: React.FC<Props> = ({
  setButtonDisabled,
  setSelectedMediaCategory,
  mediaData,
  selectedMediaCategory,
  setDisplayHelpData,
  bigMediaCategory,
  isGettingHelp,
}) => {
  return (
    <Buttons
      selectedItem={selectedMediaCategory}
      mediaData={mediaData}
      setButtonDisabled={setButtonDisabled}
      setSelectedMediaCategory={setSelectedMediaCategory}
      itemNum={2}
      setDisplayHelpData={setDisplayHelpData}
      bigMediaCategory={bigMediaCategory}
      isGettingHelp={isGettingHelp}
    />
  );
};

export default App;
