import { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Card } from 'react-bootstrap';
import api from '../api/CallOwnerApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import BackButton from '../components/BackButton';
import SelectListGroup from '../components/SelectChangeUserInfoListGroup';
import ProfilePasswordComponent from '../components/ProfilePasswordComponent';
import NotificationMailComponent from '../components/NotificationMailComponent';
import { useDispatch } from 'react-redux';
import { setGlobalDisplayName } from '../../store/displayNameReducer';
import { useErrorBoundary } from 'react-error-boundary';
import { OwnerUserInfo } from '../interface/OwnerUserInfo';
import { validateInputs } from '../validateInputs';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { ACCOUNT } from '../enum/AccountEnum';
import { PHASE } from '../enum/PhaseEnum';
import Axios from 'axios';

const ShowUser: React.FunctionComponent = () => {
  const [name, setName] = useState<string>('');
  const [representativeName, setRepresentativeName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isDisplayUserInfo, setIsDisplayUserInfo] = useState<boolean>(true);
  const [isDisplayChangePassword, setIsDisplayChangePassword] = useState<boolean>(false);
  const [isDisplayNotificationMail, setIsDisplayNotificationMail] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<any>({});
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    (async () => {
      dispatch(startLoading());
      const profile: OwnerUserInfo = await api.getProfile();
      setName(profile.name);
      setRepresentativeName(profile.representativeName);
      setPhone(profile.phone);
      setEmail(profile.email);
      setErrorMessages({});
      dispatch(stopLoading());
    })();
  }, []);

  const changeUserInfo = async () => {
    const checkParams = { ownerName: representativeName, phone, email };
    const [isError, errorMessages] = validateInputs(checkParams);
    if (isError) {
      setErrorMessages(errorMessages);
      return;
    }
    setErrorMessages({});

    try {
      await api.putProfile(representativeName, phone, email);
      dispatch(setGlobalDisplayName(representativeName));
      toast.success('ユーザー情報の変更が完了しました。', toastConfig());
    } catch (e: any) {
      if (!Axios.isAxiosError(e)) showBoundary(e);
      if (e.response.status === 400) {
        toast.error('ユーザー情報の変更に失敗しました。', toastConfig());
        return;
      }
      showBoundary(e.response);
    }
  };

  const selectDisplayInfo = () => {
    setIsDisplayUserInfo(true);
    setIsDisplayChangePassword(false);
    setIsDisplayNotificationMail(false);
  };

  const selectChangePassword = () => {
    setIsDisplayChangePassword(true);
    setIsDisplayUserInfo(false);
    setIsDisplayNotificationMail(false);
  };

  const selectNotificationMail = () => {
    setIsDisplayUserInfo(false);
    setIsDisplayChangePassword(false);
    setIsDisplayNotificationMail(true);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectListGroup
              DisplayUserInfoName="設置者担当者"
              isDisplayUserInfo={isDisplayUserInfo}
              isDisplayChangePassword={isDisplayChangePassword}
              isDisplayNotificationMail={isDisplayNotificationMail}
              isDisplayNotificationMailItem={true}
              selectDisplayInfo={selectDisplayInfo}
              selectChangePassword={selectChangePassword}
              selectNotificationMail={selectNotificationMail}
            />
          </Col>
          <Col sm={8}>
            {isDisplayUserInfo && (
              <Card>
                <Card.Body>
                  <Card.Title>設置者担当者</Card.Title>
                  設置者における利用報告の担当者を登録してください。
                  <br />
                  担当者が変更になった場合は修正のうえ、「保存」を押してください。
                  <br />
                  <br />
                  設置者名
                  <Form.Group>
                    <Form.Control type="text" value={name} disabled={true} />
                  </Form.Group>
                  設置者担当者名 <span className="badge badge-danger">必須</span>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={representativeName}
                      isInvalid={errorMessages?.ownerName}
                      onChange={(event) => {
                        setRepresentativeName(event.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errorMessages.ownerName}</Form.Control.Feedback>
                  </Form.Group>
                  連絡先電話番号 <span className="badge badge-danger">必須</span>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={phone}
                      isInvalid={errorMessages?.phone}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errorMessages.phone}</Form.Control.Feedback>
                  </Form.Group>
                  連絡先メールアドレス <span className="badge badge-danger">必須</span>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={email}
                      isInvalid={errorMessages?.email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">{errorMessages.email}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="w-100 text-muted lh-1">SARTRASまたは委託先の輿論科学協会から、連絡を差し上げる場合がございます。</div>
                  <div className="ta-right">
                    <Button
                      variant="primary"
                      className="m-10"
                      onClick={() => {
                        changeUserInfo();
                      }}
                    >
                      保存
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            )}
            {isDisplayChangePassword && <ProfilePasswordComponent api={api} />}
            {isDisplayNotificationMail && <NotificationMailComponent api={api} accountString={ACCOUNT.OWNER} phaseString={PHASE.MANAGER_CONFIRMED} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ShowUser;
