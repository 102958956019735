import { Button, Alert, Row, Col } from 'react-bootstrap';
interface Props {
  foundIdsNum: number;
  notFoundIds: string[];
  stopFiltering: () => void;
}
const Component: React.FC<Props> = ({ foundIdsNum, notFoundIds, stopFiltering }) => {
  return (
    <>
      <Col md={{ span: 12 }}>
        <Alert variant="primary">
          <Row>
            <Col md="9">
              <h4>シリアルコード検索の結果、{foundIdsNum}件の利用報告が該当しました。</h4>
            </Col>
            <Col>
              <Button
                variant="danger"
                onClick={() => {
                  stopFiltering();
                }}
              >
                シリアルコード検索解除
              </Button>
            </Col>
          </Row>
          {notFoundIds.length !== 0 && (
            <Row>
              <Col>
                以下の{notFoundIds.length}件に該当する利用報告はありませんでした。 シリアルコードをお確かめのうえ、もう一度検索してください。
                <br />
                <div className="ml-3">
                  {notFoundIds.map((id, index) => (
                    <span key={index}>
                      {' '}
                      {id}
                      <br />
                    </span>
                  ))}
                </div>
              </Col>
            </Row>
          )}
        </Alert>
      </Col>
    </>
  );
};
export default Component;
