import { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Card } from 'react-bootstrap';
import api from '../api/CallUserApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import BackButton from '../components/BackButton';
import SelectListGroup from '../components/SelectChangeUserInfoListGroup';
import UserProfilePasswordComponent from '../components/UserProfilePasswordComponent';
import { useDispatch } from 'react-redux';
import { setGlobalDisplayName } from '../../store/displayNameReducer';
import { useErrorBoundary } from 'react-error-boundary';
import { UsersUserInfo } from '../interface/UsersUserInfo';

const ShowUser: React.FunctionComponent = () => {
  const [name, setName] = useState<string>('');
  const [displayName, setDisplayName] = useState<string>('');
  const [needPassword, setNeedPassword] = useState<boolean>(false);
  const [isDisplayUserInfo, setIsDisplayUserInfo] = useState<boolean>(true);
  const [isDisplayChangePassword, setIsDisplayChangePassword] = useState<boolean>(false);
  const [isPrivate, setIsPrivate] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getData = async () => {
      const profile: UsersUserInfo = await api.getProfile();
      setName(profile.name);
      setDisplayName(profile.displayName);
      setNeedPassword(profile.private);
      setIsPrivate(profile.private);
    };
    getData();
  }, []);

  const changeUserInfo = () => {
    const putData = async () => {
      try {
        const data: UsersUserInfo = await api.putProfile(name, displayName);
        setName(data.name);
        setDisplayName(data.displayName);
        dispatch(setGlobalDisplayName(data.displayName));
        toast.success('ユーザー情報の変更が完了しました。', toastConfig());
      } catch (e: any) {
        if (e.response.status === 400) {
          toast.error('ユーザー情報の変更に失敗しました。', toastConfig());
          return;
        } else if (e.response.status === 409) {
          toast.error('ユーザー名もしくは表示名が他のユーザーと重複しています。', toastConfig());
          return;
        }
        showBoundary(e.response);
      }
    };
    putData();
  };

  const selectDisplayInfo = () => {
    setIsDisplayUserInfo(true);
    setIsDisplayChangePassword(false);
  };

  const selectChangePassword = () => {
    setIsDisplayChangePassword(true);
    setIsDisplayUserInfo(false);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectListGroup
              DisplayUserInfoName="ユーザー情報"
              isDisplayUserInfo={isDisplayUserInfo}
              isDisplayChangePassword={isDisplayChangePassword}
              selectDisplayInfo={selectDisplayInfo}
              selectChangePassword={selectChangePassword}
            />
          </Col>
          <Col sm={8}>
            {isDisplayUserInfo && (
              <Card>
                <Card.Body>
                  <Card.Title>ユーザー情報</Card.Title>
                  表示名
                  <div className="w-100 text-muted lh-1">ログイン後のユーザー選択画面で表示する名前を入力してください。</div>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={displayName}
                      onChange={(event) => {
                        setDisplayName(event.target.value);
                      }}
                    />
                  </Form.Group>
                  ユーザー名
                  <div className="w-100 text-muted lh-1">入力者を識別できる氏名などを入力してください。（教育機関担当者のみが確認できます）</div>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className="ta-right">
                    <Button
                      variant="primary"
                      className="m-10"
                      onClick={() => {
                        changeUserInfo();
                      }}
                    >
                      保存
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            )}

            {isDisplayChangePassword && (
              <UserProfilePasswordComponent
                api={api}
                needPassword={needPassword}
                setNeedPassword={setNeedPassword}
                isPrivate={isPrivate}
                setIsPrivate={setIsPrivate}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ShowUser;
