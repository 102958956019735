import { Row, Col, Button } from 'react-bootstrap';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import Stepper from '../components/Stepper';
import InputForm from './InputMediaForm';
import React, { createRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import reportApi from '../api/CallCreateReportApi';
import { createUiSchema, createTitleDict } from '../utils';
import { getMediaHelpComponent } from '../helpUtils';
import BackButton from './BackButton';
import {
  setMediaTmp,
  removeAllHalfWrittenDataById,
  saveMediaPageTmpDataAsHalfWrittenData,
  removeAllTmpDataById,
  removeWorkTmpById,
  getRequiredMediaCategoryTmpById,
  getSubBigMediaCategoryTmpById,
} from '../localStorageUtils';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

interface Props {
  userInfo: BaseUserInfo;
  isDisplayHalfWrittenButton: boolean;
  inputFormData: any;
  moveReportsPage: () => void;
  moveWorkPage: () => void;
  setDisplayHelpData: (data: any) => void;
  isFinishedGettingData: boolean;
}

const InputMedia: React.FC<Props> = ({
  userInfo,
  isDisplayHalfWrittenButton,
  inputFormData,
  moveReportsPage,
  moveWorkPage,
  isFinishedGettingData,
  setDisplayHelpData,
}) => {
  const inputFormRef = createRef<HTMLButtonElement>();
  const [formData, setFormData] = useState<any>({});
  const [validationOffFlag, setValidationOffFlag] = useState<boolean>(false);
  const [bigMediaCategory, setBigMediaCategory] = useState<string>('');
  const [mediaCategory, setMediaCategory] = useState<string>('');
  const dispatch = useDispatch();
  const [schema, setSchema] = useState<any>({});
  const [uiSchema, setUiSchema] = useState<any>({});
  const { showBoundary } = useErrorBoundary();

  const nextPage = (): void => {
    inputFormRef.current?.click();
  };

  const changeInputFormData = (formData: any, type: string) => {
    if (type === 'change') {
      setFormData(formData);
    } else {
      setMediaTmp(userInfo._id, formData);
      if (validationOffFlag) {
        // 一時保存の場合
        // 今まで保存してきたtmpをすべて保存しなおす
        removeAllHalfWrittenDataById(userInfo._id);
        saveMediaPageTmpDataAsHalfWrittenData(userInfo._id);
        removeAllTmpDataById(userInfo._id);
        // reportsページ遷移
        moveReportsPage();
        return;
      }
      removeWorkTmpById(userInfo._id);
      moveWorkPage();
    }
  };

  useEffect(() => {
    if (validationOffFlag) inputFormRef.current?.click();
  }, [validationOffFlag]);

  useEffect(() => {
    const getData = async () => {
      if (Object.keys(userInfo).length === 0) return;
      try {
        dispatch(startLoading());
        const categories = getRequiredMediaCategoryTmpById(userInfo._id);
        const categoryArray = categories.split(',');
        const subBigMediaCategoryTmp = getSubBigMediaCategoryTmpById(userInfo._id);
        setBigMediaCategory(categoryArray[0]);
        const categoryTree = categoryArray.map((item, index) => (index === 0 ? item : item.slice(2, item.length))).join(' > ');
        setMediaCategory(subBigMediaCategoryTmp ? subBigMediaCategoryTmp + ' > ' + categoryTree : categoryTree);
        setFormData(inputFormData);
        const mediaSchema = await reportApi.getMediaSchema(categoryArray);
        setSchema(mediaSchema);
        setUiSchema(createUiSchema(mediaSchema));
        const titleDict: object = createTitleDict(mediaSchema);
        Promise.allSettled(Object.keys(titleDict).map((key) => getMediaHelpComponent(categoryArray[0], titleDict[key])))
          .then((results: PromiseSettledResult<any>[]) => {
            const success: any[] = results.filter((result) => result.status === 'fulfilled');
            const Components = success.map((item) => item.value);
            setDisplayHelpData(Components);
          })
          .catch(() => {
            setDisplayHelpData([]);
          });
      } catch (e: any) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  }, [isFinishedGettingData]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="ta-left">
            <BackButton />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Stepper activeNum={1} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4>STEP2 {bigMediaCategory + 'の情報を入力してください'}</h4>
          <hr className="border-1-solid" />
          <div>
            <div className="text-black-50 mb-1">{mediaCategory}</div>
            <InputForm
              ref={inputFormRef}
              schema={schema}
              setFormData={changeInputFormData}
              isValidationOff={validationOffFlag}
              formData={formData}
              uiSchema={uiSchema}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="ta-right">
            {isDisplayHalfWrittenButton && (
              <Button
                variant="success"
                as="input"
                type="submit"
                onClick={() => {
                  setValidationOffFlag(true);
                }}
                value="書きかけ保存"
                className="m-10"
              />
            )}
            <Button
              variant="success"
              as="input"
              type="submit"
              onClick={() => {
                nextPage();
              }}
              value="次へ"
              className="m-10"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default InputMedia;
