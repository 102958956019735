import { useEffect, useState } from 'react';
import { Row, Col, Container, Button, Form, Card } from 'react-bootstrap';
import api from '../api/CallAdminApi';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import BackButton from '../components/BackButton';
import SelectListGroup from '../components/SelectChangeUserInfoListGroup';
import ProfilePasswordComponent from '../components/ProfilePasswordComponent';
import { useDispatch } from 'react-redux';
import { setGlobalDisplayName } from '../../store/displayNameReducer';
import { useErrorBoundary } from 'react-error-boundary';
import { ManagerAdminUserInfo } from '../interface/ManagerAdminUserInfo';

const ShowUser: React.FunctionComponent = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isDisplayUserInfo, setIsDisplayUserInfo] = useState<boolean>(true);
  const [isDisplayChangePassword, setIsDisplayChangePassword] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const getData = async () => {
      const profile: ManagerAdminUserInfo = await api.getProfile();
      setName(profile.name);
      setEmail(profile.email);
    };
    getData();
  }, []);

  const changeUserInfo = () => {
    const putData = async () => {
      try {
        const data: ManagerAdminUserInfo = await api.putProfile(name, email);
        dispatch(setGlobalDisplayName(data.name));
        setName(data.name);
        setEmail(data.email);
        toast.success('ユーザー情報の変更が完了しました。', toastConfig());
      } catch (e: any) {
        if (e.response.status === 400) {
          toast.error('ユーザー情報の変更に失敗しました。', toastConfig());
          return;
        }
        showBoundary(e.response);
      }
    };
    putData();
  };

  const selectDisplayInfo = () => {
    setIsDisplayUserInfo(true);
    setIsDisplayChangePassword(false);
  };

  const selectChangePassword = () => {
    setIsDisplayChangePassword(true);
    setIsDisplayUserInfo(false);
  };

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="ta-left">
              <BackButton />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <SelectListGroup
              DisplayUserInfoName="管理者"
              isDisplayUserInfo={isDisplayUserInfo}
              isDisplayChangePassword={isDisplayChangePassword}
              selectDisplayInfo={selectDisplayInfo}
              selectChangePassword={selectChangePassword}
            />
          </Col>
          <Col sm={8}>
            {isDisplayUserInfo && (
              <Card>
                <Card.Body>
                  <Card.Title>管理者</Card.Title>
                  管理者名
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </Form.Group>
                  メールアドレス
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </Form.Group>
                  <div className="ta-right">
                    <Button
                      variant="primary"
                      className="m-10"
                      onClick={() => {
                        changeUserInfo();
                      }}
                    >
                      保存
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            )}

            {isDisplayChangePassword && <ProfilePasswordComponent api={api} />}
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default ShowUser;
