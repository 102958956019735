import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

interface Prop {
  errorMsg?: string;
  resetError?: () => void;
}

const ErrorPage: React.FunctionComponent<Prop> = ({ errorMsg, resetError }) => {
  const navigate = useNavigate();
  const moveUsersReportsPage = () => {
    if (resetError) {
      resetError();
    }
    navigate('/users/reports');
  };

  const moveManagerReportsPage = () => {
    if (resetError) {
      resetError();
    }
    navigate('/manager/reports');
  };

  const moveOwnerReportsPage = () => {
    if (resetError) {
      resetError();
    }
    navigate('/owner/reports');
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={{ span: 8, offset: 2 }}>
            <Card className="card-center mt-20">
              <Card.Body>
                ページでエラーが発生しました。
                {errorMsg && <div>{errorMsg}</div>}
              </Card.Body>
              <Card.Footer className="ta-right">
                <Card>
                  <Card.Body>
                    【教員の方はこちら】
                    <Button variant="primary" className="m-1" type="button" onClick={() => moveUsersReportsPage()}>
                      教員利用報告一覧へ
                    </Button>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    【教育機関担当者の方はこちら】
                    <Button variant="primary" className="m-1" type="button" onClick={() => moveManagerReportsPage()}>
                      教育機関担当者利用報告一覧へ
                    </Button>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Body>
                    【教育機関設置者担当者の方はこちら】
                    <Button variant="primary" className="m-1" type="button" onClick={() => moveOwnerReportsPage()}>
                      教育機関設置者担当者利用報告一覧へ
                    </Button>
                  </Card.Body>
                </Card>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ErrorPage;
