import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { Button, Form, Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import { useErrorBoundary } from 'react-error-boundary';

interface UserProfilePasswordProps {
  api: any;
  needPassword: boolean;
  setNeedPassword: Dispatch<SetStateAction<boolean>>;
  isPrivate: boolean;
  setIsPrivate: Dispatch<SetStateAction<boolean>>;
}

const UserProfilePasswordComponent: React.FC<UserProfilePasswordProps> = ({ api, needPassword, setNeedPassword, isPrivate, setIsPrivate }) => {
  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [confirmPass, setConfirmPass] = useState<string>('');
  const [isOldPassError, setIsOldPassError] = useState<boolean>(false);
  const [isConfirmPassError, setIsConfirmPassError] = useState<boolean>(false);
  const { showBoundary } = useErrorBoundary();

  const changePassword = () => {
    const checkAndPutData = async () => {
      try {
        if (isPrivate) {
          const isMatchOldPass = await api.isMatchOldPassword(oldPass);
          setIsOldPassError(!isMatchOldPass);
          if (!isMatchOldPass) return;
        }
        await api.updatePassword(needPassword, newPass);
        setIsPrivate(needPassword);
        toast.success('パスワードの変更が完了しました。', toastConfig());
      } catch (e: any) {
        if (e.response.status === 400) {
          toast.error('パスワードの変更に失敗しました。', toastConfig());
          return;
        }
        showBoundary(e.response);
      }
    };
    checkAndPutData();
  };

  const checkNeedPass = () => {
    setOldPass('');
    setNewPass('');
    setConfirmPass('');
    setNeedPassword(!needPassword);
  };

  useEffect(() => {
    setIsOldPassError(false);
  }, [oldPass]);

  useEffect(() => {
    setIsConfirmPassError(newPass !== confirmPass);
  }, [newPass, confirmPass]);

  return (
    <Card>
      <Card.Body>
        <Card.Title>パスワードの変更</Card.Title>
        <Form.Group>
          <Form.Check
            type="switch"
            id="custom-switch"
            label="パスワード設定"
            checked={needPassword}
            onChange={() => {
              checkNeedPass();
            }}
          />
        </Form.Group>
        旧パスワードを入力してください。
        <Form.Group controlId="oldPass">
          <Form.Label>旧パスワード</Form.Label>
          <Form.Control
            type="password"
            isInvalid={isOldPassError}
            value={oldPass}
            onChange={(event) => setOldPass(event.target.value)}
            disabled={!isPrivate}
          />
          <Form.Control.Feedback type="invalid">パスワードが間違っています。</Form.Control.Feedback>
        </Form.Group>
        新パスワードを入力してください。
        <Form.Group controlId="newPass">
          <Form.Label>新パスワード</Form.Label>
          <Form.Control type="password" value={newPass} onChange={(event) => setNewPass(event.target.value)} disabled={!needPassword} />
        </Form.Group>
        もう一度入力してください。
        <Form.Group controlId="confirmPass">
          <Form.Label>新パスワード（確認）</Form.Label>
          <Form.Control
            type="password"
            isInvalid={isConfirmPassError}
            value={confirmPass}
            onChange={(event) => setConfirmPass(event.target.value)}
            disabled={!needPassword}
          />
          <Form.Control.Feedback type="invalid">「新パスワード」と「新パスワード（確認）」が一致しません。</Form.Control.Feedback>
        </Form.Group>
        <div className="ta-right">
          <Button
            variant="primary"
            onClick={() => {
              changePassword();
            }}
            disabled={!(isPrivate && !needPassword) && (newPass === '' || isConfirmPassError)}
          >
            パスワード変更
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
};
export default UserProfilePasswordComponent;
