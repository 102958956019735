// src/store/index.ts
import { configureStore } from '@reduxjs/toolkit';
import loadingReducer from './loadingReducer';
import institutionNameReducer from './institutionReducer';
import displayNameReducer from './displayNameReducer';
import noReportTooltipReducer from './noReportTooltipReducer';
import filterReducer from './filterReducer';

const store = configureStore({
  reducer: {
    loading: loadingReducer,
    institution: institutionNameReducer,
    displayName: displayNameReducer,
    noReportTooltip: noReportTooltipReducer,
    reportFilter: filterReducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
