import './common.css';
import { ToastContainer } from 'react-toastify';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ErrorPage from './contents/ErrorPage';
import Footer from './contents/FooterComponent';
import ScrollToTop from './contents/ScrollToTop';

import LoginUser from './contents/users/LoginUsersPage';
import SelectUser from './contents/users/SelectUserPage';
import CreateUser from './contents/users/CreateUserPage';
import ShowUsersReports from './contents/users/ShowReportsPage';
import UsersBasePage from './contents/users/BasePage';
import ShowUsersReport from './contents/users/ShowReportPage';
import ShowUsersUser from './contents/users/ShowUserPage';
import RegisterUsersLesson from './contents/users/RegisterLessonPage';
import RegisterUsersBigMediaCategory from './contents/users/RegisterBigMediaCategoryPage';
import RegisterUsersSmallMediaCategory from './contents/users/RegisterSmallMediaCategoryPage';
import RegisterUsersMedia from './contents/users/RegisterMediaPage';
import RegisterUsersWork from './contents/users/RegisterWorkPage';
import RegisterUsersWorkConfirm from './contents/users/RegisterWorkConfirmPage';
import CopyUsersBigMediaCategory from './contents/users/CopyBigMediaCategoryPage';
import CopyUsersSmallMediaCategory from './contents/users/CopySmallMediaCategoryPage';
import CopyUsersMedia from './contents/users/CopyMediaPage';
import CopyUsersWork from './contents/users/CopyWorkPage';
import CopyUsersWorkConfirm from './contents/users/CopyWorkConfirmPage';
import EditUsersLesson from './contents/users/EditLessonPage';
import EditUsersBigMediaCategory from './contents/users/EditBigMediaCategoryPage';
import EditUsersSmallMediaCategory from './contents/users/EditSmallMediaCategoryPage';
import EditUsersMedia from './contents/users/EditMediaPage';
import EditUsersWork from './contents/users/EditWorkPage';
import EditUsersWorkConfirm from './contents/users/EditWorkConfirmPage';
import UsersErrorPage from './contents/users/ErrorPage';
import ErrorFallback from './contents/components/ErrorFallBack';
import { AxiosClientProvider } from './contents/api/AxiosClientProvider';

import LoginManager from './contents/manager/LoginManagerPage';
import UserManagement from './contents/manager/UserManagementPage';
import ManagerBasePage from './contents/manager/BasePage';
import ShowManagerReports from './contents/manager/ShowReportsPage';
import ShowManagerReport from './contents/manager/ShowReportPage';
import ShowManagerUser from './contents/manager/ShowUserPage';
import RegisterManagerLesson from './contents/manager/RegisterLessonPage';
import RegisterManagerBigMediaCategory from './contents/manager/RegisterBigMediaCategoryPage';
import RegisterManagerSmallMediaCategory from './contents/manager/RegisterSmallMediaCategoryPage';
import RegisterManagerMedia from './contents/manager/RegisterMediaPage';
import RegisterManagerWork from './contents/manager/RegisterWorkPage';
import RegisterManagerWorkConfirm from './contents/manager/RegisterWorkConfirmPage';
import CopyManagerBigMediaCategory from './contents/manager/CopyBigMediaCategoryPage';
import CopyManagerSmallMediaCategory from './contents/manager/CopySmallMediaCategoryPage';
import CopyManagerMedia from './contents/manager/CopyMediaPage';
import CopyManagerWork from './contents/manager/CopyWorkPage';
import CopyManagerWorkConfirm from './contents/manager/CopyWorkConfirmPage';
import EditManagerLesson from './contents/manager/EditLessonPage';
import EditManagerBigMediaCategory from './contents/manager/EditBigMediaCategoryPage';
import EditManagerSmallMediaCategory from './contents/manager/EditSmallMediaCategoryPage';
import EditManagerMedia from './contents/manager/EditMediaPage';
import EditManagerWork from './contents/manager/EditWorkPage';
import EditManagerWorkConfirm from './contents/manager/EditWorkConfirmPage';
import ManagerErrorPage from './contents/manager/ErrorPage';

import LoginOwner from './contents/owner/LoginOwnerPage';
import OwnerBasePage from './contents/owner/BasePage';
import ShowOwnerReports from './contents/owner/ShowReportsPage';
import ShowOwnerInstitutionsPage from './contents/owner/ShowInstitutionsPage';
import ShowManagerPage from './contents/owner/ShowManagerPage';
import ShowOwnerReport from './contents/owner/ShowReportPage';
import ShowOwnerUser from './contents/owner/ShowUserPage';
import RegisterOwnerLesson from './contents/owner/RegisterLessonPage';
import RegisterOwnerBigMediaCategory from './contents/owner/RegisterBigMediaCategoryPage';
import RegisterOwnerSmallMediaCategory from './contents/owner/RegisterSmallMediaCategoryPage';
import RegisterOwnerMedia from './contents/owner/RegisterMediaPage';
import RegisterOwnerWork from './contents/owner/RegisterWorkPage';
import RegisterOwnerWorkConfirm from './contents/owner/RegisterWorkConfirmPage';
import CopyOwnerBigMediaCategory from './contents/owner/CopyBigMediaCategoryPage';
import CopyOwnerSmallMediaCategory from './contents/owner/CopySmallMediaCategoryPage';
import CopyOwnerMedia from './contents/owner/CopyMediaPage';
import CopyOwnerWork from './contents/owner/CopyWorkPage';
import CopyOwnerWorkConfirm from './contents/owner/CopyWorkConfirmPage';
import EditOwnerLesson from './contents/owner/EditLessonPage';
import EditOwnerBigMediaCategory from './contents/owner/EditBigMediaCategoryPage';
import EditOwnerSmallMediaCategory from './contents/owner/EditSmallMediaCategoryPage';
import EditOwnerMedia from './contents/owner/EditMediaPage';
import EditOwnerWork from './contents/owner/EditWorkPage';
import EditOwnerWorkConfirm from './contents/owner/EditWorkConfirmPage';
import OwnerErrorPage from './contents/owner/ErrorPage';

import LoginAdmin from './contents/admin/LoginAdminPage';
import AdminBasePage from './contents/admin/BasePage';
import ShowAdminReports from './contents/admin/ShowReportsPage';
import ShowAdminReport from './contents/admin/ShowReportPage';
import ShowAdminInstitutionsPage from './contents/admin/ShowInstitutionsPage';
import ShowAdminUser from './contents/admin/ShowUserPage';
import EditAdminLesson from './contents/admin/EditLessonPage';
import EditAdminBigMediaCategory from './contents/admin/EditBigMediaCategoryPage';
import EditAdminSmallMediaCategory from './contents/admin/EditSmallMediaCategoryPage';
import EditAdminMedia from './contents/admin/EditMediaPage';
import EditAdminWork from './contents/admin/EditWorkPage';
import EditAdminWorkConfirm from './contents/admin/EditWorkConfirmPage';
import AdminErrorPage from './contents/admin/ErrorPage';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FunctionComponent = () => {
  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <ScrollToTop>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <AxiosClientProvider>
              <Routes>
                <Route path="/*" element={<ErrorPage />} />
                {/* users */}
                <Route path="/users/login" element={<LoginUser />} />
                <Route path="/users/select" element={<SelectUser />} />
                <Route path="/users/create" element={<CreateUser />} />
                <Route path="/users/error" element={<UsersErrorPage />} />
                <Route path="/users/*" element={<UsersErrorPage />} />
                <Route path="/users" element={<UsersBasePage />}>
                  <Route path="info" element={<ShowUsersUser />} />
                  <Route path="reports" element={<ShowUsersReports />} />
                  <Route path="report/:reportId" element={<ShowUsersReport />} />
                  <Route path="report/edit/:reportId">
                    <Route path="lesson" element={<EditUsersLesson />} />
                    <Route path="bigMediaCategory" element={<EditUsersBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<EditUsersSmallMediaCategory />} />
                    <Route path="media" element={<EditUsersMedia />} />
                    <Route path="work" element={<EditUsersWork />} />
                    <Route path="work/confirm" element={<EditUsersWorkConfirm />} />
                  </Route>
                  <Route path="report/copy/:reportId">
                    <Route path="bigMediaCategory" element={<CopyUsersBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<CopyUsersSmallMediaCategory />} />
                    <Route path="media" element={<CopyUsersMedia />} />
                    <Route path="work" element={<CopyUsersWork />} />
                    <Route path="work/confirm" element={<CopyUsersWorkConfirm />} />
                  </Route>
                  <Route path="regist">
                    <Route path="lesson" element={<RegisterUsersLesson />} />
                    <Route path="bigMediaCategory" element={<RegisterUsersBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<RegisterUsersSmallMediaCategory />} />
                    <Route path="media" element={<RegisterUsersMedia />} />
                    <Route path="work" element={<RegisterUsersWork />} />
                    <Route path="work/confirm" element={<RegisterUsersWorkConfirm />} />
                  </Route>
                </Route>
                {/* manager */}
                <Route path="/manager/login" element={<LoginManager />} />
                <Route path="/manager/error" element={<ManagerErrorPage />} />
                <Route path="/manager/*" element={<ManagerErrorPage />} />
                <Route path="/manager" element={<ManagerBasePage />}>
                  <Route path="info" element={<ShowManagerUser />} />
                  <Route path="report/:reportId" element={<ShowManagerReport />} />
                  <Route path="reports" element={<ShowManagerReports />} />
                  <Route path="userManagement" element={<UserManagement />} />
                  <Route path="regist">
                    <Route path="lesson" element={<RegisterManagerLesson />} />
                    <Route path="bigMediaCategory" element={<RegisterManagerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<RegisterManagerSmallMediaCategory />} />
                    <Route path="media" element={<RegisterManagerMedia />} />
                    <Route path="work" element={<RegisterManagerWork />} />
                    <Route path="work/confirm" element={<RegisterManagerWorkConfirm />} />
                  </Route>
                  <Route path="report/edit/:reportId">
                    <Route path="lesson" element={<EditManagerLesson />} />
                    <Route path="bigMediaCategory" element={<EditManagerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<EditManagerSmallMediaCategory />} />
                    <Route path="media" element={<EditManagerMedia />} />
                    <Route path="work" element={<EditManagerWork />} />
                    <Route path="work/confirm" element={<EditManagerWorkConfirm />} />
                  </Route>
                  <Route path="report/copy/:reportId">
                    <Route path="bigMediaCategory" element={<CopyManagerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<CopyManagerSmallMediaCategory />} />
                    <Route path="media" element={<CopyManagerMedia />} />
                    <Route path="work" element={<CopyManagerWork />} />
                    <Route path="work/confirm" element={<CopyManagerWorkConfirm />} />
                  </Route>
                </Route>
                {/* owner */}
                <Route path="/owner/login" element={<LoginOwner />} />
                <Route path="/owner/error" element={<OwnerErrorPage />} />
                <Route path="/owner/*" element={<OwnerErrorPage />} />
                <Route path="/owner" element={<OwnerBasePage />}>
                  <Route path="info" element={<ShowOwnerUser />} />
                  <Route path="report/:reportId" element={<ShowOwnerReport />} />
                  <Route path="reports" element={<ShowOwnerReports />} />
                  <Route path="institutions" element={<ShowOwnerInstitutionsPage />} />
                  <Route path="institutions/managerInfo" element={<ShowManagerPage />} />
                  <Route path="regist">
                    <Route path="lesson" element={<RegisterOwnerLesson />} />
                    <Route path="bigMediaCategory" element={<RegisterOwnerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<RegisterOwnerSmallMediaCategory />} />
                    <Route path="media" element={<RegisterOwnerMedia />} />
                    <Route path="work" element={<RegisterOwnerWork />} />
                    <Route path="work/confirm" element={<RegisterOwnerWorkConfirm />} />
                  </Route>
                  <Route path="report/edit/:reportId">
                    <Route path="lesson" element={<EditOwnerLesson />} />
                    <Route path="bigMediaCategory" element={<EditOwnerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<EditOwnerSmallMediaCategory />} />
                    <Route path="media" element={<EditOwnerMedia />} />
                    <Route path="work" element={<EditOwnerWork />} />
                    <Route path="work/confirm" element={<EditOwnerWorkConfirm />} />
                  </Route>
                  <Route path="report/copy/:reportId">
                    <Route path="bigMediaCategory" element={<CopyOwnerBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<CopyOwnerSmallMediaCategory />} />
                    <Route path="media" element={<CopyOwnerMedia />} />
                    <Route path="work" element={<CopyOwnerWork />} />
                    <Route path="work/confirm" element={<CopyOwnerWorkConfirm />} />
                  </Route>
                </Route>
                {/* admin */}
                <Route path="/admin/login" element={<LoginAdmin />} />
                <Route path="/admin/*" element={<AdminErrorPage />} />
                <Route path="/admin/error" element={<AdminErrorPage />} />
                <Route path="/admin" element={<AdminBasePage />}>
                  <Route path="reports" element={<ShowAdminReports />} />
                  <Route path="info" element={<ShowAdminUser />} />
                  <Route path="institutions" element={<ShowAdminInstitutionsPage />} />
                  <Route path="report/:reportId" element={<ShowAdminReport />} />
                  <Route path="report/edit/:reportId">
                    <Route path="lesson" element={<EditAdminLesson />} />
                    <Route path="bigMediaCategory" element={<EditAdminBigMediaCategory />} />
                    <Route path="smallMediaCategory" element={<EditAdminSmallMediaCategory />} />
                    <Route path="media" element={<EditAdminMedia />} />
                    <Route path="work" element={<EditAdminWork />} />
                    <Route path="work/confirm" element={<EditAdminWorkConfirm />} />
                  </Route>
                </Route>
                <Route path="/error" element={<ErrorFallback error={{}} resetErrorBoundary={() => {}} />} />
              </Routes>
            </AxiosClientProvider>
          </ErrorBoundary>
        </ScrollToTop>
      </BrowserRouter>
      <Footer />
    </div>
  );
};

export default App;
