// src/store/loading/loadingReducer.ts
import { createSlice } from '@reduxjs/toolkit';

type NoReportTooltipState = {
  display: boolean;
};

const initialState: NoReportTooltipState = {
  display: false,
};

const noReportTooltipSlice = createSlice({
  name: 'noReportTooltip',
  initialState,
  reducers: {
    setDisplayNoReportTooltip: (state, action) => {
      state.display = action.payload;
    },
  },
});

export const { setDisplayNoReportTooltip } = noReportTooltipSlice.actions;

export default noReportTooltipSlice.reducer;
