import { Form, Col, Row } from 'react-bootstrap';
import { PHASE } from '../enum/PhaseEnum';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PhaseDescription from './PhaseDescriptionTooltip';
interface FilterComponentProps {
  filterText: string;
  onFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filterText, onFilter }) => {
  const getAvailablePhase = () => {
    if (location.pathname.includes('/users/')) {
      // usersは見た目上、未提出と提出済みを表示するが、表示内容は未提出とそれ以外すべて
      return [PHASE.NOT_SUBMITTED, '提出済み'];
    } else if (location.pathname.includes('/manager/')) {
      return [PHASE.USER_CONFIRMED, PHASE.MANAGER_CONFIRMED, PHASE.OWNER_CONFIRMED, PHASE.ADMIN_CONFIRMED];
    } else if (location.pathname.includes('/owner/')) {
      return [PHASE.MANAGER_CONFIRMED, PHASE.OWNER_CONFIRMED, PHASE.ADMIN_CONFIRMED];
    } else if (location.pathname.includes('/admin/')) {
      return [PHASE.OWNER_CONFIRMED, PHASE.ADMIN_CONFIRMED];
    } else {
      return [];
    }
  };
  const [options, setOptions] = useState<string[]>([]);
  const location = useLocation();
  useEffect(() => {
    setOptions(getAvailablePhase());
  }, []);

  return (
    <Form>
      <Row>
        <Form.Label column md="5" className="filter-phase-label pr-0">
          ステータス
          <PhaseDescription phase={filterText} />
        </Form.Label>
        <Col md="7" className="pl-0">
          <Form.Control as="select" value={filterText} onChange={onFilter}>
            {options.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterComponent;
