import { Button } from 'react-bootstrap';

const ClosePageTabButton: React.FC = () => {
  return (
    <Button variant="primary" type="button" className="m-10" onClick={() => window.close()}>
      タブを閉じる
    </Button>
  );
};

export default ClosePageTabButton;
