import { unexpectedExceptionClient, expectedExceptionClient } from './AxiosClientProvider';
import { Report } from '../interface/Report';
import { ManagerAdminUserInfo } from '../interface/ManagerAdminUserInfo';
import { User } from '../interface/User';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { BaseReport } from '../interface/BaseReport';
import { ClassInfo } from '../interface/ClassInfo';
import { Media } from '../interface/Media';
import { Work } from '../interface/Work';
import { Comment } from '../interface/Comment';

const LOGIN_API = '/v1/manager/login';
const GET_PROFILE_API = '/v1/manager/profile';
const GET_PROFILE_MATCH_PASSWORD_API = '/v1/manager/profile/match-password';
const LOGOUT_API = '/v1/manager/logout';
const GET_REPORTS_API = '/v1/manager/reports';
const BULK_DELETE_API = '/v1/manager/reports/bulk-delete';
const BULK_UPDATE_API = '/v1/manager/reports/bulk-update';
const USERS_MANAGEMENT_API = '/v1/manager/users';
const GET_REPORT_API = '/v1/manager/reports/';
const GET_DEFAULT_USER_API = '/v1/manager/users/default-user';
const BULK_CREATE_API = '/v1/manager/reports/bulk-create';
const GET_INSTITUTION_API = '/v1/manager/institutions/';
const BULK_UPDATE_USERS_API = '/v1/manager/users/bulkUpdate';
const BULK_CREATE_USERS_API = '/v1/manager/users/bulkCreate';
const BULK_DELETE_USERS_API = '/v1/manager/users/bulkDelete';

const Login = async (_id: string, password: string): Promise<any> => {
  return await expectedExceptionClient.post(LOGIN_API, { _id, password }).catch((error) => {
    throw error;
  });
};

const Logout = async (): Promise<any> => {
  return await unexpectedExceptionClient.post(LOGOUT_API).catch((error) => {
    throw error;
  });
};

const getProfile = async (): Promise<ManagerAdminUserInfo> => {
  return await unexpectedExceptionClient
    .get(GET_PROFILE_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getReports = async (phase: string, user: string): Promise<Report[]> => {
  return await unexpectedExceptionClient
    .get(GET_REPORTS_API, { params: { phase, user } })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const bulkUpdate = async (ids: BulkUpdatePhase[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_UPDATE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkDelete = async (ids: string[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_DELETE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkCreate = async (data: BaseReport[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_CREATE_API, data).catch((error) => {
    throw error;
  });
};

const getUsers = async (): Promise<User[]> => {
  return await unexpectedExceptionClient
    .get(USERS_MANAGEMENT_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getReport = async (id: string): Promise<Report> => {
  return await unexpectedExceptionClient
    .get(GET_REPORT_API + id)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const postReport = async (classInfo: ClassInfo, media: Media, work: Work, phase: string, user: string): Promise<any> => {
  return await expectedExceptionClient.post(GET_REPORTS_API, { phase, classInfo, media, work, user }).catch((error) => {
    throw error;
  });
};

const getDefaultUser = async (): Promise<string> => {
  return await unexpectedExceptionClient
    .get(GET_DEFAULT_USER_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updatePhase = async (_id: string, phase: string, comment: Comment | null): Promise<Report> => {
  return await expectedExceptionClient
    .put(GET_REPORT_API + _id, { _id, phase, comment })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const deleteReport = async (id: string): Promise<any> => {
  return await expectedExceptionClient.delete(GET_REPORT_API + id).catch((error) => {
    throw error;
  });
};

const updatePassword = async (password: string): Promise<any> => {
  return await expectedExceptionClient.put(GET_PROFILE_API, { password }).catch((error) => {
    throw error;
  });
};

const putProfile = async (name: string, phone: string, email: string): Promise<ManagerAdminUserInfo> => {
  const results = await expectedExceptionClient.put(GET_PROFILE_API, { name, phone, email });
  return results.data;
};

const isMatchOldPassword = async (password: string): Promise<boolean> => {
  return await expectedExceptionClient
    .post(GET_PROFILE_MATCH_PASSWORD_API, { password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateReport = async (id: string, classInfo: ClassInfo, media: Media, work: Work): Promise<any> => {
  return await expectedExceptionClient.put(GET_REPORT_API + id, { classInfo, media, work }).catch((error) => {
    throw error;
  });
};

const getInstitution = async (id: string): Promise<any> => {
  const results = await unexpectedExceptionClient.get(GET_INSTITUTION_API + id);
  return results.data;
};

const deleteUser = async (id: string): Promise<any> => {
  await expectedExceptionClient.delete(USERS_MANAGEMENT_API + '/' + id);
};

const deletePassword = async (id: string): Promise<any> => {
  await expectedExceptionClient.put(USERS_MANAGEMENT_API + '/' + id, { private: false });
};

const bulkDeletePasswords = async (users: User[]): Promise<any> => {
  users.forEach((element) => (element.private = false));
  const results = await expectedExceptionClient.post(BULK_UPDATE_USERS_API, { users });
  return results.data;
};

const bulkDeleteUsers = async (ids: string[]): Promise<any> => {
  const results = await expectedExceptionClient.post(BULK_DELETE_USERS_API, { ids });
  return results.data;
};

const bulkCreateUsers = async (users: User[]): Promise<any[]> => {
  const results = await expectedExceptionClient.post(BULK_CREATE_USERS_API, { users });
  return results.data;
};

const putNotificationDays = async (notificationDays: boolean[]): Promise<any> => {
  const results = await expectedExceptionClient.put(GET_PROFILE_API, { notificationDays });
  return results.data;
};

const exportedObject = {
  Login,
  getProfile,
  Logout,
  getReports,
  bulkUpdate,
  bulkDelete,
  deleteReport,
  updatePassword,
  putProfile,
  isMatchOldPassword,
  getUsers,
  getReport,
  postReport,
  getDefaultUser,
  updatePhase,
  updateReport,
  bulkCreate,
  getInstitution,
  deleteUser,
  deletePassword,
  bulkDeleteUsers,
  bulkDeletePasswords,
  bulkCreateUsers,
  putNotificationDays,
};

export default exportedObject;
