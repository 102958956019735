import { ClassInfo } from './interface/ClassInfo';
import { FrontWorkTmp } from './interface/FrontWorkTmp';
// 授業情報
const LESSON_IDENTIFIER = 'lesson';
// 「書籍・本」「新聞」など
const BIG_MEDIA_CATEGORY_IDENTIFIER = 'bigMediaCategory';
// 「(演劇等)既存の台本」もしくは「(ダンス等)既存の振り付け」
const SUB_BIG_MEDIA_CATEGORY_IDENTIFIER = 'subBigMediaCategory';
// 「書籍・本,01参考書,01市販,01紙媒体の書籍」など
const MEDIA_CATEGORY_IDENTIFIER = 'mediaCategory';
// 媒体の情報
const MEDIA_IDENTIFIER = 'media';
// 著作物の情報
const WORKS_IDENTIFIER = 'works';
// 編集種別
const EDIT_TYPE_IDENTIFIER = 'editType';

const DATA_TYPE_IDENTIFIER_LIST = [
  LESSON_IDENTIFIER,
  BIG_MEDIA_CATEGORY_IDENTIFIER,
  MEDIA_CATEGORY_IDENTIFIER,
  MEDIA_IDENTIFIER,
  SUB_BIG_MEDIA_CATEGORY_IDENTIFIER,
  WORKS_IDENTIFIER,
  EDIT_TYPE_IDENTIFIER,
];
type DataTypeIdentifier = (typeof DATA_TYPE_IDENTIFIER_LIST)[number];
const dataTypeJaDict = {
  [LESSON_IDENTIFIER]: '授業情報',
  [BIG_MEDIA_CATEGORY_IDENTIFIER]: '入手・掲載元大分類',
  [SUB_BIG_MEDIA_CATEGORY_IDENTIFIER]: '入手・掲載元大分類追加情報',
  [MEDIA_CATEGORY_IDENTIFIER]: '入手・掲載元分類',
  [MEDIA_IDENTIFIER]: '入手・掲載元の情報',
  [WORKS_IDENTIFIER]: '著作物の情報',
  [EDIT_TYPE_IDENTIFIER]: '編集種別',
};

function generateHalfWrittenDataIdentifier(id: string, dataType: DataTypeIdentifier) {
  return `${id}_${dataType}`;
}
function generateTmpDataIdentifier(id: string, dataType: DataTypeIdentifier) {
  return `${id}_${dataType}_tmp`;
}
function shouldHandleAsJson(identiFier: DataTypeIdentifier) {
  return [LESSON_IDENTIFIER, MEDIA_IDENTIFIER, WORKS_IDENTIFIER].includes(identiFier);
}

// GETTER
// 書きかけ保存（必須）
function getRequiredLocalStorageHalfWrittenData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateHalfWrittenDataIdentifier(id, dataType);
  const data = localStorage.getItem(dataIdentifier);
  if (!data) throw new Error(`ローカルストレージに${dataTypeJaDict[dataType]}（書きかけ保存）が存在しません。`);
  if (shouldHandleAsJson(dataType)) return JSON.parse(data);
  return data;
}
// 書きかけ保存（任意）
function getOptionalLocalStorageHalfWrittenData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateHalfWrittenDataIdentifier(id, dataType);
  const data = localStorage.getItem(dataIdentifier);
  if (!data) return null;
  if (shouldHandleAsJson(dataType)) return JSON.parse(data);
  return data;
}
// 一次保存（必須）
function getRequiredLocalStorageTmpData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateTmpDataIdentifier(id, dataType);
  const data = localStorage.getItem(dataIdentifier);
  if (!data) throw new Error(`ローカルストレージに${dataTypeJaDict[dataType]}（一時保存）が存在しません。`);
  if (shouldHandleAsJson(dataType)) return JSON.parse(data);
  return data;
}
// 一次保存（任意）
function getOptionalLocalStorageTmpData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateTmpDataIdentifier(id, dataType);
  const data = localStorage.getItem(dataIdentifier);
  if (!data) return null;
  if (shouldHandleAsJson(dataType)) return JSON.parse(data);
  return data;
}

// SETTER
// 書きかけ保存
function setLocalStorageHalfWrittenData(id: string, dataType: DataTypeIdentifier, data: any) {
  const dataIdentifier = generateHalfWrittenDataIdentifier(id, dataType);
  if (shouldHandleAsJson(dataType)) {
    localStorage.setItem(dataIdentifier, JSON.stringify(data));
    return;
  }
  localStorage.setItem(dataIdentifier, data);
}
// 一時保存
function setLocalStorageTmpData(id: string, dataType: DataTypeIdentifier, data: any) {
  const dataIdentifier = generateTmpDataIdentifier(id, dataType);
  if (shouldHandleAsJson(dataType)) {
    localStorage.setItem(dataIdentifier, JSON.stringify(data));
    return;
  }
  localStorage.setItem(dataIdentifier, data);
}

// REMOVE
// 書きかけ保存
function removeLocalStorageHalfWrittenData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateHalfWrittenDataIdentifier(id, dataType);
  localStorage.removeItem(dataIdentifier);
}
// 一時保存
function removeLocalStorageTmpData(id: string, dataType: DataTypeIdentifier) {
  const dataIdentifier = generateTmpDataIdentifier(id, dataType);
  localStorage.removeItem(dataIdentifier);
}

// 書きかけ保存
export function getRequiredHalfWrittenLessonById(id: string): ClassInfo {
  return getRequiredLocalStorageHalfWrittenData(id, LESSON_IDENTIFIER);
}
export function getRequiredHalfWrittenMediaCategoryById(id: string): string {
  return getRequiredLocalStorageHalfWrittenData(id, MEDIA_CATEGORY_IDENTIFIER);
}
export function getHalfWrittenSubBigMediaCategoryById(id: string): string | null {
  return getOptionalLocalStorageHalfWrittenData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER);
}
export function getRequiredHalfWrittenMediaById(id: string): object {
  return getRequiredLocalStorageHalfWrittenData(id, MEDIA_IDENTIFIER);
}
export function getHalfWrittenWorksById(id: string): FrontWorkTmp[] | null {
  return getOptionalLocalStorageHalfWrittenData(id, WORKS_IDENTIFIER);
}

// 一時保存
export function getLessonTmpById(id: string): ClassInfo | null {
  return getOptionalLocalStorageTmpData(id, LESSON_IDENTIFIER);
}
export function getRequiredLessonTmpById(id: string): ClassInfo {
  return getRequiredLocalStorageTmpData(id, LESSON_IDENTIFIER);
}
export function getBigMediaCategoryTmpById(id: string): string | null {
  return getOptionalLocalStorageTmpData(id, BIG_MEDIA_CATEGORY_IDENTIFIER);
}
export function getRequiredBigMediaCategoryTmpById(id: string): string {
  return getRequiredLocalStorageTmpData(id, BIG_MEDIA_CATEGORY_IDENTIFIER);
}
export function getSubBigMediaCategoryTmpById(id: string): string | null {
  return getOptionalLocalStorageTmpData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER);
}
export function getMediaCategoryTmpById(id: string): string | null {
  return getOptionalLocalStorageTmpData(id, MEDIA_CATEGORY_IDENTIFIER);
}
export function getRequiredMediaCategoryTmpById(id: string): string {
  return getRequiredLocalStorageTmpData(id, MEDIA_CATEGORY_IDENTIFIER);
}
export function getMediaTmpById(id: string): any | null {
  return getOptionalLocalStorageTmpData(id, MEDIA_IDENTIFIER);
}
export function getRequiredMediaTmpById(id: string): object {
  return getRequiredLocalStorageTmpData(id, MEDIA_IDENTIFIER);
}
export function getWorksTmpById(id: string): FrontWorkTmp[] | null {
  return getOptionalLocalStorageTmpData(id, WORKS_IDENTIFIER);
}
export function getRequiredWorksTmpById(id: string): FrontWorkTmp[] {
  return getRequiredLocalStorageTmpData(id, WORKS_IDENTIFIER);
}

export function setLessonTmp(id: string, data: ClassInfo): void {
  setLocalStorageTmpData(id, LESSON_IDENTIFIER, data);
}
export function setBigMediaCategoryTmp(id: string, data: string): void {
  setLocalStorageTmpData(id, BIG_MEDIA_CATEGORY_IDENTIFIER, data);
}
export function setSubBigMediaCategoryTmp(id: string, data: string): void {
  setLocalStorageTmpData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER, data);
}
export function setMediaCategoryTmp(id: string, data: string): void {
  setLocalStorageTmpData(id, MEDIA_CATEGORY_IDENTIFIER, data);
}
export function setMediaTmp(id: string, data: any): void {
  setLocalStorageTmpData(id, MEDIA_IDENTIFIER, data);
}
export function setWorksTmp(id: string, data: FrontWorkTmp[]): void {
  setLocalStorageTmpData(id, WORKS_IDENTIFIER, data);
}

// remove
export function removeBigMediaCategoriesTmpById(id: string): void {
  removeLocalStorageTmpData(id, BIG_MEDIA_CATEGORY_IDENTIFIER);
  removeLocalStorageTmpData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER);
}

export function removeSubBigMediaCategoryTmpById(id: string): void {
  removeLocalStorageTmpData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER);
}
export function removeMediaCategoryTmpById(id: string): void {
  removeLocalStorageTmpData(id, MEDIA_CATEGORY_IDENTIFIER);
}
export function removeMediaTmpById(id: string): void {
  removeLocalStorageTmpData(id, MEDIA_IDENTIFIER);
}
export function removeWorkTmpById(id: string): void {
  removeLocalStorageTmpData(id, WORKS_IDENTIFIER);
}

export const removeAllTmpDataById = (id: string) => {
  for (const key in localStorage) {
    if (key.startsWith(id) && key.endsWith('_tmp')) {
      localStorage.removeItem(key);
    }
  }
};

export const removeAllHalfWrittenDataById = (id: string) => {
  for (const dataType of DATA_TYPE_IDENTIFIER_LIST) {
    removeLocalStorageHalfWrittenData(id, dataType);
  }
};

export const removeAllTmpDataAndEditTypeById = (id: string) => {
  removeAllTmpDataById(id);
  removeLocalStorageHalfWrittenData(id, EDIT_TYPE_IDENTIFIER);
};

export function isHalfWritten(id: string): boolean {
  const editType = getOptionalLocalStorageHalfWrittenData(id, EDIT_TYPE_IDENTIFIER);
  if (editType === 'halfWritten') return true;
  return false;
}

export function saveMediaPageTmpDataAsHalfWrittenData(id: string): void {
  setLocalStorageHalfWrittenData(id, LESSON_IDENTIFIER, getRequiredLessonTmpById(id));
  setLocalStorageHalfWrittenData(id, MEDIA_CATEGORY_IDENTIFIER, getRequiredMediaCategoryTmpById(id));
  setLocalStorageHalfWrittenData(id, MEDIA_IDENTIFIER, getRequiredMediaTmpById(id));

  const subBigMediaCategory = getSubBigMediaCategoryTmpById(id);
  if (subBigMediaCategory) setLocalStorageHalfWrittenData(id, SUB_BIG_MEDIA_CATEGORY_IDENTIFIER, subBigMediaCategory);
}

export function saveWorkPageTmpDataAsHalfWrittenData(id: string): void {
  setLocalStorageHalfWrittenData(id, WORKS_IDENTIFIER, getRequiredWorksTmpById(id));
  saveMediaPageTmpDataAsHalfWrittenData(id);
}

export const existHalfWrittenData = (id: string): boolean => {
  const lesson = getOptionalLocalStorageHalfWrittenData(id, LESSON_IDENTIFIER);
  const mediaCategory = getOptionalLocalStorageHalfWrittenData(id, MEDIA_CATEGORY_IDENTIFIER);
  const media = getOptionalLocalStorageHalfWrittenData(id, MEDIA_IDENTIFIER);
  return lesson && mediaCategory && media;
};

// copy用tmp存在確認
export const existTmpData = (id: string): boolean => {
  const lessonTmp = getLessonTmpById(id);
  const mediaCategoryTmp = getMediaCategoryTmpById(id);
  const mediaTmp = getMediaTmpById(id);
  return lessonTmp && mediaCategoryTmp && mediaTmp;
};
