import { Form, Row, Col } from 'react-bootstrap';

interface FilterComponentProps {
  label: string;
  options: any[];
  filterText: string;
  onFilter: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({ filterText, onFilter, options, label }) => {
  return (
    <Form>
      <Row>
        <Form.Label column md="5" lg="3" className="filter-label pr-0">
          {label}
        </Form.Label>
        <Col md="7" lg="7" className="pl-0">
          <Form.Control as="select" value={filterText} onChange={onFilter}>
            <option value="">すべて</option>
            {options.map((item, index) => (
              <option key={index} value={item._id}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
    </Form>
  );
};
export default FilterComponent;
