import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { useState, useEffect } from 'react';
import PHASE from '../enum/PhaseEnum';

interface Props {
  phase: string;
}

const PhaseDescription: React.FC<Props> = ({ phase }) => {
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (!phase) return;
    switch (phase) {
      case PHASE.NOT_SUBMITTED:
        setDescription('報告作成直後の状態です。');
        break;
      case PHASE.USER_CONFIRMED:
        setDescription('報告が教育機関担当者様へ提出された状態です。');
        break;
      case PHASE.MANAGER_CONFIRMED:
        setDescription('報告が教育機関設置者担当者様に提出された状態です。');
        break;
      case PHASE.OWNER_CONFIRMED:
        setDescription('報告がSARTRASに提出された状態です。');
        break;
      case PHASE.ADMIN_CONFIRMED:
        setDescription('報告をSARTRASが確認、受領した状態です。');
        break;
      case '提出済み':
        setDescription('ご提出済みの利用報告です。');
        break;
      default:
        setDescription('');
    }
  }, [phase]);

  return (
    <OverlayTrigger trigger={['hover', 'click']} placement="bottom" overlay={<Tooltip id="phase_description_tooltip">{description}</Tooltip>}>
      <QuestionCircleFill color="#c6c8ca" size={20} className="m-1" />
    </OverlayTrigger>
  );
};

export default PhaseDescription;
