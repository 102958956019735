// src/store/loading/loadingReducer.ts
import { createSlice } from '@reduxjs/toolkit';

type DisplayNameState = {
  displayName: string;
};

const initialState: DisplayNameState = {
  displayName: '',
};

const displayNameSlice = createSlice({
  name: 'displayName',
  initialState,
  reducers: {
    setGlobalDisplayName: (state, action) => {
      state.displayName = action.payload;
    },
  },
});

export const { setGlobalDisplayName } = displayNameSlice.actions;

export default displayNameSlice.reducer;
