import { Button, Modal, Form } from 'react-bootstrap';
import { createRef } from 'react';
import { getLessonSchema, getLessonUiSchema } from '../../utils';
import InputForm from '../InputMediaForm';
import { useState } from 'react';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  changeFormData: (formData: any) => void;
  selectInstitutions: boolean;
  institutions: any[];
  changeInstitution: (value: string) => void;
}

const ChangeLessonModal: React.FC<ModalProps> = ({ show, setShow, changeFormData, selectInstitutions, institutions, changeInstitution }) => {
  const inputFormRef = createRef<HTMLButtonElement>();
  const [validation, setValidation] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [formData, setFormData] = useState<any>({});

  const copyAndChangeClassInfo = () => {
    inputFormRef.current?.click();
  };

  const changeInputFormData = (formData: any, type: string) => {
    if (type === 'submit') {
      if (selectInstitutions && !selected) {
        setValidation(true);
        setFormData(formData);
        return;
      }
      setValidation(false);
      changeFormData(formData);
    }
  };

  const changeItem = (value: string) => {
    setSelected(value);
    changeInstitution(value);
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>授業情報を編集してコピー</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {selectInstitutions && (
          <div>
            <Form.Label>
              教育機関 <span className="badge badge-danger">必須</span>
            </Form.Label>
            <Form.Control
              as="select"
              isInvalid={validation}
              onChange={(event) => {
                changeItem(event.target.value);
              }}
            >
              <option value=""></option>
              {institutions.map((item, index) => (
                <option key={index} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">教育機関を選択してください。</Form.Control.Feedback>
          </div>
        )}
        <div>
          選択した利用報告の授業情報<b>だけ</b>を変更して、コピーします。
        </div>
        <div>
          <InputForm
            ref={inputFormRef}
            schema={getLessonSchema()}
            setFormData={changeInputFormData}
            isValidationOff={false}
            formData={formData}
            uiSchema={getLessonUiSchema()}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            copyAndChangeClassInfo();
          }}
        >
          コピーして作成
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChangeLessonModal;
