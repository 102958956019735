import Stepper from 'react-stepper-horizontal';
import React from 'react';
interface StepperProps {
  activeNum: number;
}

const steps = [{ title: '授業情報の入力' }, { title: '掲載元の選択' }, { title: '著作物情報の入力' }];

const Step = React.memo((props: StepperProps) => {
  return (
    <div>
      <Stepper steps={steps} activeStep={props.activeNum} />
    </div>
  );
});

export default Step;
