import Header from '../components/header/UsersHeader';
import { UsersUserInfo } from '../interface/UsersUserInfo';
import { Institution } from '../interface/Institution';
import { useEffect, useState } from 'react';
import api from '../api/CallUserApi';
import { Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import Loading from '../components/Loading';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalInstitution } from '../../store/institutionReducer';
import { setGlobalDisplayName } from '../../store/displayNameReducer';
import { existHalfWrittenData } from '../localStorageUtils';

const BasePage: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<UsersUserInfo>({} as UsersUserInfo);
  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
  const [isDisableHalfWrittenBtn, setIsDisableHalfWrittenBtn] = useState<boolean>(true);
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const profile: UsersUserInfo = await api.getProfile();
      setUserInfo(profile);
      dispatch(setGlobalDisplayName(profile.displayName));
      const institution: Institution = await api.getInstitution('me');
      dispatch(
        setGlobalInstitution({
          responsibleTerm: institution.responsibleTerm,
          name: institution.name,
        })
      );
    })();
  }, []);

  useEffect(() => {
    const isRegistOrEditOrCopyPage =
      location.pathname.includes('/copy/') || location.pathname.includes('/edit/') || location.pathname.includes('/regist/');

    // ページ更新（離脱）時にダイアログを表示（利用報告新規作成、編集、コピー時のみ）
    const onBeforeunload = (e: any) => {
      if (isRegistOrEditOrCopyPage) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    if (!userInfo) return;
    setIsDisableHalfWrittenBtn(!existHalfWrittenData(userInfo._id));
    if (isRegistOrEditOrCopyPage) {
      // 登録、コピー、編集画面表示時は書きかけ・新規のボタンをdisable
      setIsButtonDisable(true);
    } else {
      setIsButtonDisable(false);
    }

    // ページ更新（離脱）時のイベントを設定
    window.addEventListener('beforeunload', onBeforeunload, false);
    return () => window.removeEventListener('beforeunload', onBeforeunload, false);
  }, [location, userInfo]);

  return (
    <>
      {isLoading && <Loading />}
      <Header user={userInfo} isBtnDisabled={isButtonDisable} isDisableHalfWrittenBtn={isDisableHalfWrittenBtn} />
      <Outlet />
    </>
  );
};

export default BasePage;
