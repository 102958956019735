import Header from '../components/header/AdminHeader';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { useEffect, useState } from 'react';
import api from '../api/CallAdminApi';
import { Outlet, useLocation } from 'react-router-dom';
import { RootState } from '../../store';
import Loading from '../components/Loading';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setGlobalDisplayName } from '../../store/displayNameReducer';

const BasePage: React.FunctionComponent = () => {
  const location = useLocation();
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const profile: BaseUserInfo = await api.getProfile();
      dispatch(setGlobalDisplayName(profile.name));
    })();
  }, []);

  useEffect(() => {
    const isRegistOrEditOrCopyPage =
      location.pathname.includes('/copy/') || location.pathname.includes('/edit/') || location.pathname.includes('/regist/');

    // ページ更新（離脱）時にダイアログを表示（利用報告編集時のみ）
    const onBeforeunload = (e: any) => {
      if (isRegistOrEditOrCopyPage) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    // ページ更新（離脱）時のイベントを設定
    window.addEventListener('beforeunload', onBeforeunload, false);
    return () => {
      window.removeEventListener('beforeunload', onBeforeunload, false);
    };
  }, [location]);

  return (
    <>
      {isLoading && <Loading />}
      <Header />
      <Outlet />
    </>
  );
};

export default BasePage;
