import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallOwnerApi';
import { useParams } from 'react-router-dom';
import InputWork from '../components/InputWork';
import { DANCE_TITLE, THEATER_TITLE } from '../utils';
import { getWorksTmpById, getRequiredMediaCategoryTmpById } from '../localStorageUtils';
import { FrontWork } from '../interface/FrontWork';
import { useErrorBoundary } from 'react-error-boundary';

const RegisterWork: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const [works, setWorks] = useState<FrontWork[]>([]);
  const params = useParams();
  const [reportId, setReportId] = useState<string>('');
  const { showBoundary } = useErrorBoundary();

  const moveNextUrl = () => {
    navigate('/owner/report/edit/' + reportId + '/work/confirm');
  };

  useEffect(() => {
    const getData = async () => {
      const id = params ? params.reportId : '';
      if (!id) throw Error('report id パラメータが存在しません');
      setReportId(id);
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const prevWorks = getWorksTmpById(profile._id);
      if (prevWorks) {
        setWorks([
          {
            data: {
              formData: prevWorks[0].formData,
              subCategory: prevWorks[0].subCategory,
            },
            disabled: prevWorks[0].disabled,
          },
        ]);
        return;
      }
      const report = await api.getReport(id);
      try {
        const categoriesTmp = getRequiredMediaCategoryTmpById(profile._id);
        const categories = report.media.categories;
        if (categories[0] === DANCE_TITLE || categories[0] === THEATER_TITLE) categories.shift();
        if (categoriesTmp === String(categories)) {
          // 過去のジャンルと一致する
          setWorks([
            {
              data: { formData: report.work.info, subCategory: report.work.subCategory },
              disabled: false,
            },
          ]);
        } else {
          setWorks([{ data: {}, disabled: true }]);
        }
      } catch (e) {
        showBoundary(e);
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <InputWork
          userInfo={userInfo}
          moveNextUrl={moveNextUrl}
          moveReportsPage={() => {}}
          worksByUpperLayer={works}
          isDisplayAddDeleteButton={false}
          isDisplayHalfWrittenButton={false}
        />
      </Container>
    </>
  );
};
export default RegisterWork;
