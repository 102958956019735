export interface FrontWorkTmp {
  id: number;
  formData: any;
  disabled: boolean;
  subCategory: string;
  titles: object;
  schema?: any;
  uiSchema?: any;
}

export function isFrontWorkTmpType(obj: any): obj is FrontWorkTmp {
  return obj !== null && typeof obj === 'object' && typeof obj.formData === 'object' && typeof obj.subCategory === 'string';
}
