import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallAdminApi';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { toastConfig } from '../utils';
import ShowWorkConfirm from '../components/ShowWorkConfirm';
import { useErrorBoundary } from 'react-error-boundary';
import {
  getRequiredLessonTmpById,
  getRequiredMediaCategoryTmpById,
  getSubBigMediaCategoryTmpById,
  getRequiredMediaTmpById,
  getRequiredWorksTmpById,
  removeAllTmpDataById,
} from '../localStorageUtils';
import Axios from 'axios';

const RegisterWorkConfirm: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const [reportId, setReportId] = useState<string>('');
  const params = useParams();
  const { showBoundary } = useErrorBoundary();

  const save = () => {
    const saveReports = async () => {
      try {
        const lesson = getRequiredLessonTmpById(userInfo._id);
        const mediaCategoryStr = getRequiredMediaCategoryTmpById(userInfo._id);
        const subBigMediaCategoryStr = getSubBigMediaCategoryTmpById(userInfo._id);
        const mediaInfo = getRequiredMediaTmpById(userInfo._id);
        const works = getRequiredWorksTmpById(userInfo._id);
        const mediaCategoryArray = mediaCategoryStr.split(',');
        if (subBigMediaCategoryStr) mediaCategoryArray.unshift(subBigMediaCategoryStr);
        const media = { categories: mediaCategoryArray, info: mediaInfo };
        for (const work of works) {
          const workInfo = { subCategory: work.subCategory, info: work.formData };
          await api.updateReport(reportId, lesson, media, workInfo);
        }
        navigate('/admin/reports');
      } catch (e: any) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
        if (e.response.status === 400 || e.response.status === 404) {
          toast.error('利用報告を更新できませんでした。', toastConfig());
          navigate('/admin/reports');
        } else {
          showBoundary(e.response);
        }
      } finally {
        removeAllTmpDataById(userInfo._id);
      }
    };
    saveReports();
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const id = params ? params.reportId : '';
      if (!id) throw Error('report id パラメータが存在しません');
      setReportId(id);
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <ShowWorkConfirm userInfo={userInfo} saveReports={save} />
      </Container>
    </>
  );
};
export default RegisterWorkConfirm;
