// src/store/loading/loadingReducer.ts
import { createSlice } from '@reduxjs/toolkit';

type FilterState = {
  phase: string;
  user: string;
  institution: string;
  owner: string;
  ids: string[];
};

const initialState: FilterState = {
  phase: '',
  user: '',
  institution: '',
  owner: '',
  ids: [],
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setPhase: (state, action) => {
      state.phase = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setInstitution: (state, action) => {
      state.institution = action.payload;
    },
    setOwner: (state, action) => {
      state.owner = action.payload;
    },
    setIds: (state, action) => {
      state.ids = action.payload;
    },
    refreshAll: (state) => {
      state.phase = '';
      state.user = '';
      state.institution = '';
      state.owner = '';
      state.ids = [];
    },
  },
});

export const { setPhase, setUser, setInstitution, setOwner, setIds, refreshAll } = filterSlice.actions;
export default filterSlice.reducer;
