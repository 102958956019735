import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallManagerApi';
import { useParams } from 'react-router-dom';
import InputWork from '../components/InputWork';
import { getInputListByPrevData, DANCE_TITLE, THEATER_TITLE } from '../utils';
import { getWorksTmpById, existTmpData, setSubBigMediaCategoryTmp, setLessonTmp, setMediaCategoryTmp, setMediaTmp } from '../localStorageUtils';
import { FrontWork } from '../interface/FrontWork';

const RegisterWork: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const navigate = useNavigate();
  const params = useParams();
  const [reportId, setReportId] = useState<string>('');
  const [works, setWorks] = useState<FrontWork[]>([]);

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const id = params ? params.reportId : '';
      if (!id) throw Error('report id パラメータが存在しません');
      setReportId(id);
      const prevWorks = getWorksTmpById(profile._id);
      if (prevWorks) {
        setWorks(getInputListByPrevData(prevWorks));
        return;
      }
      if (!existTmpData(profile._id)) {
        const report = await api.getReport(id);
        const categories = report.media.categories;
        if (categories[0] === DANCE_TITLE || categories[0] === THEATER_TITLE) {
          setSubBigMediaCategoryTmp(profile._id, categories[0]);
          categories.shift();
        }
        setLessonTmp(profile._id, report.classInfo);
        setMediaCategoryTmp(profile._id, String(categories));
        setMediaTmp(profile._id, report.media.info);
      }
      setWorks([{ data: {}, disabled: true }]);
    };
    getData();
  }, []);

  const moveNextUrl = () => {
    navigate('/manager/report/copy/' + reportId + '/work/confirm');
  };

  const reportsPage = () => {
    navigate('/manager/reports');
  };

  return (
    <>
      <Container>
        <InputWork
          userInfo={userInfo}
          moveNextUrl={moveNextUrl}
          moveReportsPage={reportsPage}
          worksByUpperLayer={works}
          isDisplayAddDeleteButton={true}
          isDisplayHalfWrittenButton={true}
        />
      </Container>
    </>
  );
};
export default RegisterWork;
