module.exports = {
  _id: '利用報告ID',
  subject: '授業名',
  grade: '学年',
  personCount: '(2)履修者人数',
  responsibleYear: '報告対象年',
  responsibleMonth: '報告対象月',
  institutionId: '教育機関ID',
  schoolType: '(1)教育機関種別',
};
