import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../api/CallOwnerApi';
import { getNextUrl, getMediaUrl } from '../utils';
import InputBigMediaCategory from '../components/InputBigMediaCategory';
import HelpArea from '../components/HelpComponent';
import {
  getBigMediaCategoryTmpById,
  getSubBigMediaCategoryTmpById,
  isHalfWritten,
  getRequiredHalfWrittenMediaCategoryById,
  getHalfWrittenSubBigMediaCategoryById,
} from '../localStorageUtils';
import { useErrorBoundary } from 'react-error-boundary';

const RegisterBigMediaCategory: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [bigMediaCategory, setBigMediaCategory] = useState('');
  const [subBigMediaCategory, setSubBigMediaCategory] = useState('');
  const [helps, setHelps] = useState<any[]>([]);
  const [helpPaddingTop, setHelpPaddingTop] = useState<any>({});
  const navigate = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();

  const nextPage = (): void => {
    const nextPage = getNextUrl(location.pathname);
    navigate(nextPage);
  };

  const moveMediaPage = () => {
    const nextPage = getMediaUrl(location.pathname);
    navigate(nextPage);
  };

  useEffect(() => {
    const getData = async () => {
      const profile: BaseUserInfo = await api.getProfile();
      setUserInfo(profile);
      const prevBigMediaCategory = getBigMediaCategoryTmpById(profile._id);
      const prevSubBigMediaCategory = getSubBigMediaCategoryTmpById(profile._id);
      if (prevSubBigMediaCategory) {
        setSubBigMediaCategory(prevSubBigMediaCategory);
      }

      if (prevBigMediaCategory) {
        setBigMediaCategory(prevBigMediaCategory);
        return;
      }

      if (isHalfWritten(profile._id)) {
        // 途中復帰の場合
        try {
          const mediaCategory = getRequiredHalfWrittenMediaCategoryById(profile._id);
          const mediaCategoryArray = mediaCategory.split(',');
          setBigMediaCategory(mediaCategoryArray[0]);
          const subBigMediaCategoryStr = getHalfWrittenSubBigMediaCategoryById(profile._id);
          if (subBigMediaCategoryStr) setSubBigMediaCategory(subBigMediaCategoryStr);
        } catch (e) {
          showBoundary(e);
        }
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <InputBigMediaCategory
              bigMediaCategory={bigMediaCategory}
              movePage={nextPage}
              setBigMediaCategory={setBigMediaCategory}
              userInfo={userInfo}
              subBigMediaCategory={subBigMediaCategory}
              setSubBigMediaCategory={setSubBigMediaCategory}
              moveMediaPage={moveMediaPage}
              setDisplayHelpData={setHelps}
              setHelpPaddingTop={setHelpPaddingTop}
            />
          </Col>
          <HelpArea helps={helps} helpPaddingTop={helpPaddingTop} />
        </Row>
      </Container>
    </>
  );
};
export default RegisterBigMediaCategory;
