import { Button, Modal } from 'react-bootstrap';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  type: string;
  nextStep: () => void;
}

const BulkModal: React.FC<ModalProps> = ({ show, setShow, type, nextStep }) => {
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{type}確認</Modal.Title>
      </Modal.Header>
      <Modal.Body>チェックした利用報告を{type}します。よろしいですか？</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            nextStep();
          }}
        >
          {type}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BulkModal;
