import { useEffect, useState } from 'react';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import api from '../api/CallUserApi';
import reportApi from '../api/CallCreateReportApi';
import { useParams } from 'react-router-dom';
import { startLoading, stopLoading } from '../../store/loadingReducer';
import { useDispatch } from 'react-redux';
import SmallMediaCategory from '../components/InputSmallMediaCategory';
import HelpArea from '../components/HelpComponent';
import { getRequiredBigMediaCategoryTmpById, getMediaCategoryTmpById } from '../localStorageUtils';
import { setSmallMediaCategorySelected } from '../utils';
import { MediaCategory } from '../interface/MediaCategory';
import { useErrorBoundary } from 'react-error-boundary';
import Axios from 'axios';

const RegisterSmallMediaCategory: React.FunctionComponent = () => {
  const [userInfo, setUserInfo] = useState<BaseUserInfo>({} as BaseUserInfo);
  const [mediaCategoryData, setMediaCategoryData] = useState<MediaCategory[]>([]);
  const [selectedMediaCategory, setSelectedMediaCategory] = useState<string>('');
  const [bigMediaCategory, setBigMediaCategory] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportId, setReportId] = useState<string>('');
  const params = useParams();
  const [helps, setHelps] = useState<any[]>([]);
  const { showBoundary } = useErrorBoundary();

  const nextPage = (): void => {
    navigate('/users/report/copy/' + reportId + '/media');
  };

  useEffect(() => {
    const getData = async () => {
      try {
        dispatch(startLoading());
        const profile: BaseUserInfo = await api.getProfile();
        setUserInfo(profile);
        const prevBigMediaCategory = getRequiredBigMediaCategoryTmpById(profile._id);
        setBigMediaCategory(prevBigMediaCategory);
        const data = await reportApi.getMediaCategory(prevBigMediaCategory);
        setMediaCategoryData(setSmallMediaCategorySelected(data[0].children, selectedMediaCategory));

        const id = params ? params.reportId : '';
        if (!id) throw Error('report id パラメータが存在しません');
        setReportId(id);
        const prevMediaCategory = getMediaCategoryTmpById(profile._id);
        if (prevMediaCategory) {
          setSelectedMediaCategory(prevMediaCategory);
          return;
        }
      } catch (e) {
        if (!Axios.isAxiosError(e)) showBoundary(e);
      } finally {
        dispatch(stopLoading());
      }
    };
    getData();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={9} lg={9}>
            <SmallMediaCategory
              bigMediaCategory={bigMediaCategory}
              movePage={nextPage}
              mediaCategory={mediaCategoryData}
              selectedMediaCategoryByUpperLayer={selectedMediaCategory}
              userInfo={userInfo}
              setDisplayHelpData={setHelps}
            />
          </Col>
          <HelpArea helps={helps} />
        </Row>
      </Container>
    </>
  );
};
export default RegisterSmallMediaCategory;
