import { unexpectedExceptionClient, expectedExceptionClient } from './AxiosClientProvider';
import { PHASE } from '../enum/PhaseEnum';
import { Report } from '../interface/Report';
import { UsersUserInfo } from '../interface/UsersUserInfo';
import { User } from '../interface/User';
import { BulkUpdatePhase } from '../interface/BulkUpdatePhase';
import { BaseReport } from '../interface/BaseReport';
import { ClassInfo } from '../interface/ClassInfo';
import { Media } from '../interface/Media';
import { Work } from '../interface/Work';

const INSTITUTION_LOGIN_API = '/v1/users/institution-login';
const INSTITUTION_LOGOUT_API = '/v1/users/institution-logout';
const INSTITUTION_API = '/v1/users/institutions/';
const GET_USERS_API = '/v1/users/';
const GET_PROFILE_API = '/v1/users/profile';
const GET_PROFILE_MATCH_PASSWORD_API = '/v1/users/profile/match-password';
const GET_REPORTS_API = '/v1/users/reports';
const GET_REPORT_API = '/v1/users/reports/';
const LOGIN_API = '/v1/users/login';
const LOGOUT_API = '/v1/users/logout';
const BULK_DELETE_API = '/v1/users/reports/bulk-delete';
const BULK_UPDATE_API = '/v1/users/reports/bulk-update';
const BULK_CREATE_API = '/v1/users/reports/bulk-create';

const InstitutionLogin = async (_id: string, password: string): Promise<any> => {
  return await expectedExceptionClient.post(INSTITUTION_LOGIN_API, { _id, password }, { withCredentials: true }).catch((error) => {
    throw error;
  });
};

const InstitutionLogout = async (): Promise<any> => {
  return await unexpectedExceptionClient.post(INSTITUTION_LOGOUT_API).catch((error) => {
    throw error;
  });
};

const getInstitution = async (id: string): Promise<any> => {
  const results = await unexpectedExceptionClient.get(INSTITUTION_API + id);
  return results.data;
};

const getUsers = async (): Promise<User[]> => {
  return await unexpectedExceptionClient
    .get(GET_USERS_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const getSession = async (): Promise<any> => {
  return await expectedExceptionClient.get(GET_USERS_API).catch((error) => {
    throw error;
  });
};

const createUser = async (name: string, displayName: string, privateFlag: boolean, password: string): Promise<any[]> => {
  return await expectedExceptionClient
    .post(GET_USERS_API, { name, displayName, private: privateFlag, password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const Login = async (_id: string, password: string): Promise<any> => {
  return await expectedExceptionClient.post(LOGIN_API, { _id, password }).catch((error) => {
    throw error;
  });
};

const Logout = async (): Promise<any> => {
  return await unexpectedExceptionClient.post(LOGOUT_API).catch((error) => {
    throw error;
  });
};

const getProfile = async (): Promise<UsersUserInfo> => {
  return await unexpectedExceptionClient
    .get(GET_PROFILE_API)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const putProfile = async (name: string, displayName: string): Promise<UsersUserInfo> => {
  return await expectedExceptionClient
    .put(GET_PROFILE_API, { name, displayName })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const isMatchOldPassword = async (password: string): Promise<boolean> => {
  return await expectedExceptionClient
    .post(GET_PROFILE_MATCH_PASSWORD_API, { password })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updatePassword = async (privateFlag: boolean, password: string): Promise<any> => {
  const param = {
    password: privateFlag ? password : 'null',
    private: privateFlag,
  };
  return await expectedExceptionClient.put(GET_PROFILE_API, param).catch((error) => {
    throw error;
  });
};

const getReport = async (id: string): Promise<Report> => {
  return await unexpectedExceptionClient
    .get(GET_REPORT_API + id)
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updatePhase = async (_id: string, phase: string): Promise<Report> => {
  return await expectedExceptionClient
    .put(GET_REPORT_API + _id, { _id, phase })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const updateReport = async (id: string, classInfo: ClassInfo, media: Media, work: Work): Promise<any> => {
  return await expectedExceptionClient.put(GET_REPORT_API + id, { classInfo, media, work }).catch((error) => {
    throw error;
  });
};

const deleteReport = async (id: string): Promise<any> => {
  return await expectedExceptionClient.delete(GET_REPORT_API + id).catch((error) => {
    throw error;
  });
};

const getReports = async (phase: string): Promise<Report[]> => {
  const params = phase === PHASE.NOT_SUBMITTED ? { phase: PHASE.NOT_SUBMITTED } : { phaseNe: PHASE.NOT_SUBMITTED };
  return await unexpectedExceptionClient
    .get(GET_REPORTS_API, { params })
    .then((results) => {
      return results.data;
    })
    .catch((error) => {
      throw error;
    });
};

const bulkDelete = async (ids: string[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_DELETE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkUpdate = async (ids: BulkUpdatePhase[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_UPDATE_API, ids).catch((error) => {
    throw error;
  });
};

const bulkCreate = async (data: BaseReport[]): Promise<any> => {
  return await expectedExceptionClient.post(BULK_CREATE_API, data).catch((error) => {
    throw error;
  });
};

const postReport = async (classInfo: ClassInfo, media: Media, work: Work, phase: string): Promise<any> => {
  return await expectedExceptionClient.post(GET_REPORTS_API, { phase, classInfo, media, work }).catch((error) => {
    throw error;
  });
};

const exportedObject = {
  putProfile,
  isMatchOldPassword,
  InstitutionLogin,
  Login,
  getUsers,
  getProfile,
  getReports,
  bulkDelete,
  bulkUpdate,
  postReport,
  createUser,
  getReport,
  updateReport,
  deleteReport,
  updatePhase,
  updatePassword,
  InstitutionLogout,
  Logout,
  bulkCreate,
  getSession,
  getInstitution,
};

export default exportedObject;
