import Validator from 'validatorjs';

// バリデーションルール
const allRules = {
  managerName: 'required',
  ownerName: 'required',
  phone: 'required|numeric',
  email: 'required|email',
};

// エラーメッセージ
const customErrorMessages = {
  required: '必須項目です。',
  numeric: '半角数字で入力してください。',
  email: 'メールアドレスの形式が正しくありません。',
};

// 適切なエラーメッセージ連想配列に変換する
const generateErrMessasge = (value) => {
  const errMessage = {};
  for (const key in value) {
    errMessage[key] = value[key][0];
  }
  return errMessage;
};

// 必要なルールを取り出す
const takeOutRules = (value) => {
  const rules = {};
  for (const key in value) {
    if (key in allRules) rules[key] = allRules[key];
  }
  return rules;
};

// バリデーションチェック関数本体
export const validateInputs = (data) => {
  // 必要なルールを取得
  const rules = takeOutRules(data);
  // バリデーションチェック
  const validation = new Validator(data, rules, customErrorMessages);
  // バリデーション結果とエラー文を返す
  return [validation.fails(), generateErrMessasge(validation.errors.all())];
};
