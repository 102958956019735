import { FrontWorkTmp } from '../interface/FrontWorkTmp';
import { BaseUserInfo } from '../interface/BaseUserInfo';
import { Row, Table, Card, Col, Button } from 'react-bootstrap';
import Stepper from '../components/Stepper';
import { useEffect, useState } from 'react';
import BackButton from './BackButton';
import { getStringValue } from '../utils';
import { getRequiredWorksTmpById } from '../localStorageUtils';

interface Props {
  userInfo: BaseUserInfo;
  saveReports: () => void;
}

interface displayData {
  title: string;
  value: string;
}

const WorkConfirm: React.FC<Props> = ({ userInfo, saveReports }) => {
  const [formDataArray, setFormDataArray] = useState<FrontWorkTmp[]>([]);

  const getConfirmTable = (item: any, index: number): JSX.Element => {
    return (
      <Card className="m-10" key={index}>
        <Card.Body>
          <Card.Title>著作物:{index + 1}</Card.Title>
          <Table responsive>
            <thead>
              <tr>
                <th className="w-25">項目名</th>
                <th>入力内容</th>
              </tr>
            </thead>
            <tbody>
              {item.formData.map((form: displayData, index: number) => (
                <tr key={index}>
                  <td className="w-25">{form.title}</td>
                  <td className="text-break">{getStringValue(form.value)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  };

  const setTitle = (works: FrontWorkTmp[]) => {
    for (let work of works) {
      let newFormData: displayData[] = [];
      for (const formKey in work.formData) {
        const value = work.formData[formKey];
        const titles = work.titles;
        const title = titles[formKey];
        if (!title) continue;
        newFormData.push({ title: title, value: value });
      }
      work.formData = newFormData;
    }
    setFormDataArray(works);
  };

  useEffect(() => {
    if (Object.keys(userInfo).length === 0) return;
    setTitle(getRequiredWorksTmpById(userInfo._id));
  }, [userInfo]);

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="ta-left">
            <BackButton />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Stepper activeNum={2} />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4>STEP3 入力した著作物の情報です。</h4>
          <hr className="border-1-solid" />
          <div>{formDataArray.map((item, index) => getConfirmTable(item, index))}</div>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="ta-right">
            <Button
              variant="success"
              as="input"
              type="submit"
              onClick={() => {
                saveReports();
              }}
              value="登録"
              className="m-10"
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
export default WorkConfirm;
