export const getBigMediaCategoryHelpComponent = async (bigCategory: string): Promise<any> => {
  return await import(`./components/Helps/${bigCategory}/bigMediaCategory`)
    .then((response) => {
      return [response.default];
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getSmallMediaCategoryHelpComponent = async (bigCategory: string, contentName: string): Promise<any> => {
  return await import(`./components/Helps/${bigCategory}/SmallMediaCategorys/${contentName}`)
    .then((response) => {
      return response.default;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getMediaHelpComponent = async (bigCategory: string, contentName: string): Promise<any> => {
  return await import(`./components/Helps/${bigCategory}/Media/${contentName}`)
    .then((response) => {
      return response.default;
    })
    .catch((error) => {
      throw error.response;
    });
};

export const getWorkHelpComponent = async (bigCategory: string, contentName: string): Promise<any> => {
  return await import(`./components/Helps/${bigCategory}/Work/${contentName}`)
    .then((response) => {
      return response.default;
    })
    .catch((error) => {
      throw error.response;
    });
};
