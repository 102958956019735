export interface ReportCreateUser {
  _id: string;
  name: string;
  institution: {
    responsibleTerm: {
      year: number;
      month: number;
    };
    _id: string;
    schoolType: string;
  };
}

export function isReportCreateUserType(obj: any): obj is ReportCreateUser {
  return (
    obj !== null && typeof obj === 'object' && typeof obj._id === 'string' && typeof obj.name === 'string' && typeof obj.institution === 'object'
  );
}
