import { Button, Modal, Card, Alert, CardDeck, Row, Col } from 'react-bootstrap';
import { useState } from 'react';

interface ModalProps {
  show: boolean;
  setShow: (showFlag: boolean) => void;
  nextStep: (from: string) => void;
}

const SelectCopyFromModal: React.FC<ModalProps> = ({ show, setShow, nextStep }) => {
  const [from, setFrom] = useState<string>('');
  return (
    <Modal
      show={show}
      onHide={() => {
        setShow(false);
      }}
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>コピーするSTEPの選択</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>どちらかをクリックして選択してください。</div>
        <CardDeck>
          <Row>
            <Col md="12" lg="6">
              <Card
                body
                onClick={() => {
                  setFrom('class');
                }}
                className={from === 'class' ? 'select-copy-from' : ''}
              >
                <div>
                  <h3>STEP1をコピー</h3>
                </div>
                <div>
                  <Alert variant="dark">
                    <div className="w-50 d-inline-block">
                      STEP1: 授業情報
                      <br />
                      例: 理科 2学年 32人
                    </div>
                    <div className="w-50 d-inline-block text-right">
                      <div className="copy-mark p-1">
                        <b>
                          <i>COPY</i>
                        </b>
                      </div>
                    </div>
                  </Alert>
                  <Alert variant="info">
                    STEP2: 入手・掲載元情報
                    <br />
                    例: 書籍タイトル「やさしい国語」
                  </Alert>
                  <Alert variant="info">
                    STEP3: 利用した分量
                    <br />
                    例: 出典作品名「愛と美について」
                  </Alert>
                </div>
                <div>
                  STEP1はコピー元の情報を使用するので、入力を省略できます。<b>STEP2、STEP3</b>
                  のみご入力いただき、新しく利用報告を作成します。
                </div>
              </Card>
            </Col>
            <Col md="12" lg="6">
              <Card
                body
                onClick={() => {
                  setFrom('classAndMedia');
                }}
                className={from === 'classAndMedia' ? 'select-copy-from' : ''}
              >
                <div>
                  <h3>STEP1、STEP2をコピー</h3>
                </div>
                <div>
                  <Alert variant="dark">
                    <div className="w-50 d-inline-block">
                      STEP1: 授業情報
                      <br />
                      例: 理科 2学年 32人
                    </div>
                    <div className="w-50 d-inline-block text-right">
                      <div className="copy-mark p-1">
                        <b>
                          <i>COPY</i>
                        </b>
                      </div>
                    </div>
                  </Alert>
                  <Alert variant="dark">
                    <div className="w-75 d-inline-block">
                      STEP2: 入手・掲載元情報
                      <br />
                      例: 書籍タイトル「やさしい国語」
                    </div>
                    <div className="w-25 d-inline-block text-right">
                      <div className="copy-mark p-1">
                        <b>
                          <i>COPY</i>
                        </b>
                      </div>
                    </div>
                  </Alert>
                  <Alert variant="info">
                    STEP3: 利用した分量
                    <br />
                    例: 出典作品名「愛と美について」
                  </Alert>
                </div>
                <div>
                  STEP1、STEP2はコピー元の情報を使用するので、入力を省略できます。<b>STEP3</b>
                  のみご入力いただき、新しく利用報告を作成します。
                </div>
              </Card>
            </Col>
          </Row>
        </CardDeck>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShow(false);
          }}
        >
          閉じる
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            nextStep(from);
          }}
          disabled={from === ''}
        >
          入力画面へ
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SelectCopyFromModal;
