import { Button, Modal } from 'react-bootstrap';

export interface NavigateCautionModalProps {
  isDisplayModal: boolean;
  handleCloseModal: () => void;
  handleClickOk: () => void;
}

const NavigateCautionModal: React.FC<NavigateCautionModalProps> = ({ isDisplayModal, handleCloseModal, handleClickOk }) => {
  return (
    <Modal show={isDisplayModal} onHide={handleCloseModal}>
      <Modal.Header closeButton>
        <Modal.Title>注意</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ fontSize: '18px', textAlign: 'center' }}>
          登録を完了せずに移動すると、
          <br />
          編集中のデータは破棄されます。
          <br />
          本当に<b style={{ color: 'red' }}>破棄しますか？</b>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'space-between' }}>
        <Button variant="outline-primary" onClick={handleCloseModal}>
          キャンセル
        </Button>
        <Button variant="danger" onClick={handleClickOk}>
          破棄する
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NavigateCautionModal;
